import React from 'react';
import MainLayout from '../Layout/MainLayout';

const RightToBearPersonal = () => {
  return (
    <>
      <MainLayout>
        <div className="personal-gun">
          <div className="container">
            <div className="row">
              <div className="insurance-gun-user">
                <img src="../../images/gun-user.webp" alt="" />
              </div>
              <div className="handgun">
                <h3 className="handgun-title">
                  So You Bought a Handgun for Personal Protection, Now What?
                </h3>
                <p className="handgun-sub-title">MAY 5, 2023</p>
              </div>
              <div className="protection">
                <a href="#" className="share-link">
                  <img src="../../images/share.svg" alt="" />
                  Share
                </a>
                <p className="owning-text">
                  Owning a handgun for personal protection doesn’t mean one is
                  adequately prepared to use it for that purpose. Just like
                  owning a car doesn’t mean one is ready to start driving it.
                  There is some learning and skill development that should take
                  place first.
                </p>
                <p className="gun-link-firearm">
                  There were more than{' '}
                  <a href="https://unclezo.com/Users/alonsorobles/Downloads/NICS_Firearm_Checks_-_Month_Year.pdf">
                    30 million NICS firearm background checks per year over the
                    last three years
                  </a>
                  . Those are the three years with the most background checks
                  conducted since NICS was implemented in 1998. The increasing
                  year over year trend of background checks is, with a few
                  exceptions, typical. However, the total number of background
                  checks conducted in the past three years is pretty impressive
                  when we consider that they account for almost 25% of all the
                  background checks conducted in the past quarter century.
                  What’s even more interesting is that industry news claims that
                  a large percentage of the background checks can be attributed
                  to first time gun owners who for one reason or another were
                  purchasing a gun for personal protection. Furthermore, news
                  reports also suggest that women and minorities are the fastest
                  growing demographic of gun owners who bought a gun for
                  personal protection. While I personally think that is
                  fantastic news, it brings us to the point of this post – there
                  are a lot of new gun owners with new handguns who bought them
                  for self defense purposes that may, or more likely are may
                  not, be wondering what’s next. The goal of this is to help
                  those folks with that. If that isn’t you, then chances are you
                  know somebody who is in that position and hopefully this post
                  will help you help them.
                </p>
                <p className="gun-link-firearm">
                  So you bought a handgun for personal protection, now what?
                  There is a saying, “with great power comes great
                  responsibility.” And as Tom Givens points out in his{' '}
                  <a href="https://unclezo.com/tag/rangemaster-firearms-training-services/">
                    coursework and writings
                  </a>
                  , with that gun we hold the power of life and death in our
                  hands and all it takes to exercise that power is a single
                  decision and moving a finger. It should go without saying that
                  using that power without adequate competency can have dire
                  consequences regardless of whether it was intentional or
                  unintentional. As such, it behooves each of us to become
                  competent practitioners of the handgun which requires a modest
                  investment into the following areas at a minimum:
                </p>
                <p className="gun-link-firearm pt-0 mb-0">
                  <ol>
                    <li>Understanding the rules of gun safety</li>
                    <li>Becoming familiar with the operation of the handgun</li>
                    <li>
                      Knowing the laws and regulations that pertain to the
                      possession and use of the handgun
                    </li>
                    <li>
                      Attaining at least a minimum level of competence and
                      proficiency with the handgun
                    </li>
                  </ol>
                </p>
                <p className="gun-link-firearm">
                  I hold a very strong opinion that these areas of study are
                  best conducted with the assistance of high-quality
                  professional instruction. However,{' '}
                  <a href="https://unclezo.com/2021/04/17/how-to-select-a-good-instructor/">
                    finding a quality instructor
                  </a>{' '}
                  is a challenge in of itself and it may not be feasible for
                  some folks given local availability or financial constraints.
                  So let’s dig a little deeper into these topics while keeping
                  in mind that this is simply a starting point.
                </p>
                <p className="owning-text">Rules of Gun Safety</p>
                <p className="gun-link-firearm">
                  The very first thing a new gun owner should concern themselves
                  with is safe gun handling. As elementary, fundamental, and
                  simple as this sounds, it’s not necessarily the easiest thing
                  to do. Assuming the purchased handgun included a manual, which
                  may not be the case if the purchased firearm was previously
                  owned, it is likely to contain a section on gun safety.
                  However, the rules presented are not uniform from one manual
                  to the next. The number of safety rules vary and may be
                  presented in a manner that is too brief to be meaningful or
                  too wordy making it hard to digest and internalize. Some of
                  the rules are specific to the firearm rather than universal.
                </p>
                <p className="gun-link-firearm">
                  Another approach that is very common today is to perform a
                  search on the internet for “gun safety rules”, “rules of gun
                  safety”, or something similar. The success of this approach
                  depends on the search engines results, which can vary from one
                  search engine to another, and the quality of the source
                  selected by the new gun owner. One search I performed, yielded
                  a link to a resource from the NRA, a resource from the NSSF, a
                  link to a gun control advocacy group (that had absolutely
                  nothing related to safe gun handling), another link to a
                  resource from a law firm, and several more. Some of the
                  sources were better than others, but even the high quality
                  sources varied in the number of rules and how they were
                  presented.
                </p>
                <p className="gun-link-firearm">
                  I’m getting long winded here with this initial concern so I’ll
                  wrap it up. What the new gun owner needs are a few universal
                  rules that are easy to digest and remember to create a safe
                  gun handling foundation that good safe habits and behaviors
                  can be built on. Thankfully there are four well established
                  rules that have been widely adopted that apply universally to
                  all firearms. I’ll list them here with links to posts where
                  each rule is explored in depth.
                </p>
                <p className="gun-link-firearm">
                  <ol>
                    <li>
                      <a href="https://unclezo.com/2020/04/17/first-rule-of-gun-safety-distilled-treat-every-firearm-like-its-loaded/">
                        Treat Every Firearm Like It’s Loaded
                      </a>
                    </li>
                    <li>
                      <a href="https://unclezo.com/2020/04/23/second-rule-of-gun-safety-distilled-keep-the-muzzle-pointed-in-a-safe-direction/">
                        Keep the Gun Pointed in a Safe Direction
                      </a>
                    </li>
                    <li>
                      <a href="https://unclezo.com/2020/04/29/third-rule-of-gun-safety-distilled-only-place-your-finger-on-the-trigger-when-you-are-ready-to-shoot/">
                        Only Place Your Finger on the Trigger When You Are Ready
                        to Shoot
                      </a>
                    </li>
                    <li>
                      <a href="https://unclezo.com/2020/05/05/fourth-rule-of-gun-safety-distilled-be-sure-of-your-target-and-what-lies-beyond-it/">
                        Be Sure of Your Target and What’s Beyond It
                      </a>
                    </li>
                  </ol>
                </p>
                <p className="gun-link-firearm">
                  Living by and exercising these rules seriously regardless of
                  the circumstances significantly reduces the occurrence of
                  unintentional discharges and the consequences that follow. The
                  possibility of an unintentional discharge will always remain,
                  but it is virtually reduced to accidents that result from
                  mechanical failure which are exceedingly rare.
                </p>
                <p className="owning-text mb-4">Get To Know Your Handgun</p>
                <p className="gun-link-firearm">
                  Read the manual. Yeah, I know. That’s an old school and nerdy
                  thing to do. Nevertheless, there is a lot of good information
                  in there. Reading it, or atleast skimming it, will expose the
                  new gun owner to nomenclature in addition to providing
                  instructions how to load and clear the firearm. It also
                  contains maintenance procedures that are necessary to keep the
                  gun running reliably and extend its serviceable lifespan. If
                  the gun didn’t come with a manual for whatever reason, then
                  contact the manufacturer to request one or check the
                  manufacturer’s website for a downloadable soft copy.
                </p>
                <p className="gun-link-firearm">
                  It’s worthwhile to make a note of and understand the handgun’s
                  safety features. There is a chance that the gun purchased may
                  not be well suited for defensive applications. For example,
                  the manual may point out that the handgun isn’t drop safe. I
                  suspect this scenario is unusual, but it’s possible and this
                  may be an indicator to seek better assistance in selecting
                  another handgun that is better suited to one’s needs.
                </p>
                <p className="gun-link-firearm">
                  After reading the manual, one should consider practicing the
                  loading and clearing procedures using inert ammunition,
                  commonly referred to as dummy rounds or snap caps, to become
                  familiar with them. There are several companies that make
                  dummy rounds. For common cartridges, I like the{' '}
                  <a href="https://stactionpro.com/">
                    S.T. Action Pro dummy rounds
                  </a>{' '}
                  which are made from a real brass case with a one-piece ABS
                  insert. Oftentimes,{' '}
                  <a href="https://www.amazon.com/stores/STACTIONPROINC/page/E5520BF6-D428-44D2-839A-2BE02AE4C158?&linkCode=sl2&tag=unclezo-20&linkId=d85b84be2a8fe60ee50ee7e7c568fd7f&language=en_US&ref_=as_li_ss_tl">
                    these can be found on Amazon
                  </a>
                  . Another option that I’ve found works relatively well are the{' '}
                  <a href="https://www.tiptonclean.com/snapcaps/?avad=285149_d310bc28d&utm_medium=affiliate&utm_source=avantlink&utm_campaign=227481&utm_term=NA&utm_content=285149-ale-ale">
                    Tipton Snap Caps
                  </a>{' '}
                  which{' '}
                  <a href="https://www.amazon.com/stores/page/1671D596-C1ED-4003-BC42-9D33EACF0DC4?ingress=2&visitId=e07aba98-d68e-4c0b-a0ac-c2686d01371e&linkCode=sl2&tag=unclezo-20&linkId=b1b56b81699e223dc6356ea68f08b469&language=en_US&ref_=as_li_ss_tl">
                    can also be found on Amazon
                  </a>
                  .
                </p>
                <p className="gun-link-firearm">
                  The goal here is to become intimately familiar with safe
                  operation and administration of the hand gun. One should know
                  how to check its condition to determine whether or not the
                  firearm is loaded, how to load the firearm, how to unload and
                  clear the firearm, how to engage and disengage active and
                  passive safety mechanisms, and how to fire the firearm. Using
                  the manual for this can be challenging for a new owner
                  depending on the quality and thoroughness of the manual.
                  Internet searches might also be helpful, however, be wary of
                  content produced by anyone other than the manufacturer or a
                  known professional as instructional content from a random
                  person on the internet may be incorrect.
                </p>
                <p className="owning-text">Know the Law</p>
                <p className="gun-link-firearm">
                  There are a large number of laws and regulations that apply to
                  handguns. Additionally, we are living in an era where laws
                  surrounding storage, possession, transportation, and usage are
                  changing rapidly. An example of this, is the adoption of
                  permitless carry laws which have now been adopted by 28 states
                  in the United States of America over the past two decades. The
                  point is every gun owner should, in my opinion, make it a
                  point to know those laws at least for the jurisdiction they
                  reside in and those they travel to and through with their
                  handgun in order to avoid catching a charge due as a result of
                  ignorance that would like lead to the loss of their gun
                  rights, freedom, and additional consequences.
                </p>
                <p className="gun-link-firearm">
                  Going through the local handgun carry permitting or licensing
                  process is arguably the best way to become familiar with the
                  current laws in a person’s locale. That said, it can be an
                  expensive and time consuming process. Even if one decides to
                  go through that process, it remains incumbent upon them to
                  stay informed of future changes to the laws.
                </p>
                <p className="gun-link-firearm">
                  A good resource to remain informed on current handgun laws
                  throughout the USA is the{' '}
                  <a href="https://www.handgunlaw.us/">Handgunlaw.us</a>{' '}
                  website. However, the content there can sometimes be a little
                  difficult to digest and understand since it contains
                  reproductions of the actual legal statutes that apply to each
                  individual state. It’s not a bad idea to be a member of a
                  legal service or having a legal plan, like{' '}
                  <a href="https://unclezo.com/2023/03/08/right-to-bear-gets-a-facelift/">
                    the plan offered by Right to Bear
                  </a>{' '}
                  who happens to be a sponsor of this blog and offers readers
                  who use the promo code “UNCLEZO” 10% off the first year of the
                  plan, that provides access to an attorney who can help with
                  deciphering the legalese and understanding how the laws apply
                  to you.
                </p>
                <p className="gun-link-firearm">
                  Bottom line is ignorance of the law is not defendable. Knowing
                  the laws is the responsibility of the gun owner.
                </p>
                <p className="owning-text">Minimum Proficiency</p>
                <p className="gun-link-firearm">
                  This is very difficult to do on your own. Okay, I may be
                  projecting here a bit based on my anecdotal experience, but I
                  can honestly say that I don’t think I would be as proficient
                  with my handgun had it not been for the professional
                  instruction I sought out and received. That’s not to say it
                  can’t be done, but it’s a lot easier to do with help and not
                  all help is created equal. My initial foray into handgun
                  shooting received assistance from a few friends who were
                  “lifelong shooters”. Later, I learned that range safety
                  officers and even members of law enforcement aren’t
                  necessarily good shooters. Even if some of those folks were
                  good shooters, they weren’t necessarily adept at teaching
                  others to become good shooters.
                </p>
                <p className="gun-link-firearm">
                  The biggest challenge, in my opinion, is developing the proper
                  mindset which is critical to being{' '}
                  <a href="https://unclezo.com/2023/04/21/are-you-mentally-prepared-to-defend-yourself/">
                    mentally prepared for a violent confrontation
                  </a>{' '}
                  that requires the use of the handgun for personal protection.
                  Beyond that are several other pretty big challenges, one of
                  those is figuring out what to practice to improve the skills
                  that are important for personal protection. Another huge
                  challenge is figuring out how to measure skills against
                  realistic achievable goals. There are several other smaller,
                  albeit still important, challenges to the self taught journey.
                  These include things like learning to evaluate and select
                  adequate supporting equipment like holsters, training aids,
                  and ammunition. A training program tailored to this, like the{' '}
                  <a href="https://unclezo.com/tag/kr-defensive-pistol-skills-program/">
                    Defensive Pistol Skills Program
                  </a>{' '}
                  from KR Training, makes this part of the journey much easier
                  to navigate.
                </p>
                <p className="gun-link-firearm">
                  Alternatively a training aid like the{' '}
                  <a href="https://unclezo.com/2022/04/21/the-mantis-x10-elite-shooting-performance-system-as-a-dry-fire-aid/">
                    Mantis X
                  </a>{' '}
                  (another sponsor of the blog) can also facilitate the
                  proficiency development process and should be considered when
                  facing financial constraints or limited availability of local
                  quality training. A couple of good books on the topic, like{' '}
                  <a href="https://www.amazon.com/Concealed-Carry-Class-Self-Defense-Tactics/dp/1946267953?keywords=tom+givens+concealed+carry+class&qid=1683146354&sprefix=tom+givens,aps,197&sr=8-1&linkCode=sl1&tag=unclezo-20&linkId=3b11d3ecf95cdbc37a99f28eafee25b3&language=en_US&ref_=as_li_ss_tl">
                    Tom Givens’ Concealed Carry Class
                  </a>{' '}
                  and{' '}
                  <a href="https://www.amazon.com/Dry-Fire-Primer-Annette-Evans/dp/1983427535?keywords=the+dry+fire+primer+by+annette+evans&qid=1683146371&sprefix=annette+evans+,aps,151&sr=8-1&linkCode=sl1&tag=unclezo-20&linkId=e579912d817611a2a09c7eec5a412ca9&language=en_US&ref_=as_li_ss_tl">
                    Annette Evans’ The Dry Fire Primer
                  </a>{' '}
                  can also provide a bit of guidance here.
                </p>
                <p className="gun-link-firearm">
                  The definition of minimum level is a bit murky. John Daub has
                  done a lot of research and work to help define a minimum level
                  of competence which is documented in the Strategies and{' '}
                  <a href="https://www.amazon.com/Strategies-Standards-Defensive-Handgun-Training/dp/B0BXN99X7Z?keywords=strategies+and+standards+for+defensive+handgun+training+by+karl+rehn&qid=1683146574&sprefix=karl+rehn+,aps,126&sr=8-1&linkCode=sl1&tag=unclezo-20&linkId=b9e272cfd1d050d1e3a813b769183f3e&language=en_US&ref_=as_li_ss_tl">
                    Standards for Defensive Handgun Training book (2023 Edition)
                  </a>{' '}
                  which was co-authored with Karl Rehn. Their work can give us
                  an idea of what that level might look like, but it is
                  ultimately up to each of us to decide if our proficiency level
                  is up to par for the things we are likely to protect ourselves
                  from based on our circumstances and environment.
                </p>
                <p className="gun-link-firearm">
                  At the end of it all, it is ultimately up to the new gun owner
                  to develop the level of proficiency they desire. Reaching that
                  level is going to require doing a little homework, possibly
                  seeking a little help, and a fair amount of elbow grease. It
                  simply will not materialize without doing the work.
                </p>
                <p className="gun-link-firearm">
                  At the end of it all, it is ultimately up to the new gun owner
                  to develop the level of proficiency they desire. Reaching that
                  level is going to require doing a little homework, possibly
                  seeking a little help, and a fair amount of elbow grease. It
                  simply will not materialize without doing the work.
                </p>
                <p className="gun-link-firearm">
                  <a href="https://unclezo.com/2023/05/03/so-you-bought-a-handgun-for-personal-protection-now-what/">
                    Published by Uncle Zo
                  </a>
                </p>
                <div className="blog-back">
                  <a href="/righttoblog">
                    <i className="fa fa-long-arrow-left"></i>
                    Back to blog
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainLayout>
    </>
  );
};

export default RightToBearPersonal;
