import Cookies from 'js-cookie';

export function getAuthToken(key) {
  return Cookies.get(key);
}

export function getUser(key) {
  return localStorage.getItem(key);
}

export function logoutUser() {
  Cookies.remove('userToken');
  Cookies.remove('isSubscribed');
  localStorage.clear();
}

export function setLocalData(key, data) {
  localStorage.setItem(key, data);
}

export function getLocalData(key) {
  return localStorage.getItem(key);
}
export function deleteLocalData(key) {
  return localStorage.removeItem(key);
}
export function setLocalDataObject(key, data) {
  return localStorage.setItem(key, JSON.stringify(data));
}

export function getLocalDataObject(key) {
  return JSON.parse(localStorage.getItem(key));
}
