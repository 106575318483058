import React from 'react';

const Video = () => {
  return (
    <>
      <div className="video-frame">
        <div className="container">
          <div className="row">
            <video
              src="../../video/right-to-bear.mp4"
              poster="../../images/Promo_Video_Image.png"
              width={'100%'}
              controls
            ></video>
          </div>
        </div>
      </div>
    </>
  );
};

export default Video;
