import React from 'react';
import MainLayout from '../Layout/MainLayout';

const TermsConditions = () => {
  return (
    <>
      <MainLayout>
        <div className="about-banner event">
          <div className="about-outer">
            <h1 className="banner-heading">TERMS & CONDITIONS</h1>
          </div>
        </div>
        <div className="terms-and-conditions">
          <div className="container">
            <div className="row">
              <div className="conditions">
                <p className="title">
                  The website located at protectwithbear.com (the{' '}
                  <span>“Site”</span> ) is a copyrighted work belonging to{' '}
                  <span>
                    Right to Bear Association, LLC (“Company”, “us”, “our”,
                  </span>{' '}
                  and <span>“we”</span>). Certain features of the Site may be
                  subject to additional guidelines, terms, or rules, which will
                  be posted on the Site in connection with such features. These
                  Terms (“Terms” govern your access and use of the website
                  (collectively, the “Services”) provided by Right to Bear and
                  its subsidiaries (collectively, the “Right to Bear”), so
                  please read these Terms carefully. All such additional terms,
                  guidelines, and rules are incorporated by reference into these
                  Terms.
                </p>
                <p className="title pt-3">
                  References to &quot;you&quot; in these Terms means you, your
                  duly authorized representatives, and any entity you may
                  represent in connection with your use of the Services. Any
                  reference made in these Terms to &quot;Right to Bear&quot;
                  shall be deemed to have been made to Right to Bear, Inc., its
                  successors, assignees, and US subsidiaries and affiliates, as
                  well as any company that controls Right to Bear, directly or
                  indirectly, and any other subsidiary of that controlling
                  company.
                </p>
                <p className="title pt-3">
                  Not all of the products or services (“Services”) described on
                  SITE are available in all areas of the United States and you
                  may not be eligible for them. We reserve the right to
                  determine eligibility. By accessing or using our Services, you
                  agree to be bound by these Terms and by our Privacy Policy. If
                  you do not agree to these Terms, which incorporates the
                  Privacy Policy by reference please do not access or otherwise
                  use our Services or any information contained herein.
                </p>
                <p className="title">
                  THESE TERMS OF USE (THESE <span>“TERMS”</span>) SET FORTH THE
                  LEGALLY BINDING TERMS AND CONDITIONS THAT GOVERN YOUR USE OF
                  THE SITE. BY ACCESSING OR USING THE SITE, YOU ARE ACCEPTING
                  THESE TERMS (ON BEHALF OF YOURSELF OR THE ENTITY THAT YOU
                  REPRESENT), AND YOU REPRESENT AND WARRANT THAT YOU HAVE THE
                  RIGHT, AUTHORITY, AND CAPACITY TO ENTER INTO THESE TERMS (ON
                  BEHALF OF YOURSELF OR THE ENTITY THAT YOU REPRESENT). YOU MAY
                  NOT ACCESS OR USE THE SITE OR ACCEPT THE TERMS IF YOU ARE NOT
                  AT LEAST 18 YEARS OLD. IF YOU DO NOT AGREE WITH ALL OF THE
                  PROVISIONS OF THESE TERMS, DO NOT ACCESS AND/OR USE THE SITE.
                </p>
                <p className="title pt-3">
                  THESE TERMS REQUIRE THE USE OF ARBITRATION (SECTION 10.2) ON
                  AN INDIVIDUAL BASIS TO RESOLVE DISPUTES, RATHER THAN JURY
                  TRIALS OR CLASS ACTIONS, AND ALSO LIMIT THE REMEDIES AVAILABLE
                  TO YOU IN THE EVENT OF A DISPUTE.
                </p>
                <p className="title pt-3">
                  THE HEADINGS AND CAPTIONS USED IN THESE TERMS ARE INSERTED FOR
                  CONVENIENCE ONLY AND WILL NOT AFFECT THE MEANING OR
                  INTERPRETATION OF THESE TERMS. DO NOT RELY ON OUR APP FOR YOUR
                  FINANCIAL DECISIONS. THE CONTENT ON THE RIGHT TO BEAR WEBSITE,
                  AND OTHER CONTENT IS FOR CONVENIENCE AND INFORMATION PURPOSES
                  ONLY. NOTHING THEREIN SHOULD BE CONSTRUED AS RENDERING TAX,
                  LEGAL, INVESTMENT, OR ACCOUNTING ADVICE. THE POSTING OF ANY
                  PROSPECTUS OR ANY OTHER INFORMATION IS NOT A RECOMMENDATION OR
                  OPINION FOR YOU TO BUY OR SELL ANY PRODUCT OR PARTICIPATE IN
                  ANY TRANSACTION.
                </p>
                <p className="title pt-3">
                  <span>1.1</span>
                  <span className="ps-5"> Account Creation.</span> In order to
                  use certain features of the Site, you must register for an
                  account (“Account”) and provide certain information about
                  yourself as prompted by the account registration form. You
                  represent and warrant that: (a) all required registration
                  information you submit is truthful and accurate; (b) you will
                  maintain the accuracy of such information. You may delete your
                  Account at any time, for any reason, by following the
                  instructions on the Site. Company may suspend or terminate
                  your Account in accordance with Section 8.
                </p>
                <p className="title pt-3">
                  <span>1.2</span>{' '}
                  <span className="ps-5">Account Responsibilities.</span>You are
                  responsible for maintaining the confidentiality of your
                  Account login information and are fully responsible for all
                  activities that occur under your Account. You agree to
                  immediately notify Company of any unauthorized use, or
                  suspected unauthorized use of your Account or any other breach
                  of security. Company cannot and will not be liable for any
                  loss or damage arising from your failure to comply with the
                  above requirements.
                </p>
                <p className="title pt-3">
                  <span>2.2</span>
                  <span className="ps-5">Certain Restrictions.</span> The rights
                  granted to you in these Terms are subject to the following
                  restrictions: (a) you shall not license, sell, rent, lease,
                  transfer, assign, distribute, host, or otherwise commercially
                  exploit the Site, whether in whole or in part, or any content
                  displayed on the Site; (b) you shall not modify, make
                  derivative works of, disassemble, reverse compile or reverse
                  engineer any part of the Site; (c) you shall not access the
                  Site in order to build a similar or competitive website,
                  product, or service; and (d) except as expressly stated
                  herein, no part of the Site may be copied, reproduced,
                  distributed, republished, downloaded, displayed, posted or
                  transmitted in any form or by any means. Unless otherwise
                  indicated, any future release, update, or other addition to
                  functionality of the Site shall be subject to these Terms. All
                  copyright and other proprietary notices on the Site (or on any
                  content displayed on the Site) must be retained on all copies
                  thereof.
                </p>
                <p className="title pt-3">
                  You acknowledge and agree that Company will have no obligation
                  to provide you with any support or maintenance in connection
                  with the Site.
                </p>
                <p className="title pt-3">
                  Excluding any User Content that you may provide (defined
                  below), you acknowledge that all the intellectual property
                  rights, including copyrights, patents, trade marks, and trade
                  secrets, in the Site and its content are owned by Company or
                  Company’s suppliers. Neither these Terms (nor your access to
                  the Site) transfers to you or any third party any rights,
                  title or interest in or to such intellectual property rights,
                  except for the limited access rights expressly set forth in
                  Section 2.1. Company and its suppliers reserve all rights not
                  granted in these Terms. There are no implied licenses granted
                  under these Terms.
                </p>
                <p className="title pt-3">
                  <span> 3.</span> <span className="ps-5">User Content</span>
                </p>
                <p className="title pt-3">
                  <span>3.1</span>
                  <span className="ps-5">User Content.</span>{' '}
                  <span>“User Content”</span> means any and all information and
                  content that a user submits to, or uses with, the Site (e.g.,
                  content in the user’s profile or postings). You are solely
                  responsible for your User Content. You assume all risks
                  associated with use of your User Content, including any
                  reliance on its accuracy, completeness or usefulness by
                  others, or any disclosure of your User Content that personally
                  identifies you or any third party. You hereby represent and
                  warrant that your User Content does not violate our Acceptable
                  Use Policy (defined in Section 3.3). You may not represent or
                  imply to others that your User Content is in any way provided,
                  sponsored or endorsed by Company. Because you alone are
                  responsible for your User Content, you may expose yourself to
                  liability if, for example, your User Content violates the
                  Acceptable Use Policy. Company is not obligated to backup any
                  User Content, and your User Content may be deleted at any time
                  without prior notice. You are solely responsible for creating
                  and maintaining your own backup copies of your User Content if
                  you desire
                </p>
                <p className="title pt-3">
                  <span>3.2 </span>
                  <span className="ps-5">License.</span> You hereby grant (and
                  you represent and warrant that you have the right to grant) to
                  Company an irrevocable, nonexclusive, royalty-free and fully
                  paid, worldwide license to reproduce, distribute, publicly
                  display and perform, prepare derivative works of, incorporate
                  into other works, and otherwise use and exploit your User
                  Content, and to grant sublicenses of the foregoing rights,
                  solely for the purposes of including your User Content in the
                  Site. You hereby irrevocably waive (and agree to cause to be
                  waived) any claims and assertions of moral rights or
                  attribution with respect to your User Content.
                </p>
                <p className="title pt-3">
                  <span> 3.3</span>
                  <span className="ps-5">Acceptable Use Policy. </span> The
                  following terms constitute our “Acceptable Use Policy”:
                </p>
                <p className="title pt-3">
                  <span className="pe-5"> (a)</span>You agree not to use the
                  Site to collect, upload, transmit, display, or distribute any
                  User Content (i) that violates any third-party right,
                  including any copyright, trademark, patent, trade secret,
                  moral right, privacy right, right of publicity, or any other
                  intellectual property or proprietary right; (ii) that is
                  unlawful, harassing, abusive, tortious, threatening, harmful,
                  invasive of another’s privacy, vulgar, defamatory, false,
                  intentionally misleading, trade libelous, pornographic,
                  obscene, patently offensive, promotes racism, bigotry, hatred,
                  or physical harm of any kind against any group or individual
                  or is otherwise objectionable; (iii) that is harmful to minors
                  in any way; or (iv) that is in violation of any law,
                  regulation, or obligations or restrictions imposed by any
                  third party.
                </p>
                <p className="title pt-3">
                  <span className="pe-5">(b)</span> In addition, you agree not
                  to: (i) upload, transmit, or distribute to or through the Site
                  any computer viruses, worms, or any software intended to
                  damage or alter a computer system or data; (ii) send through
                  the Site unsolicited or unauthorized advertising, promotional
                  materials, junk mail, spam, chain letters, pyramid schemes, or
                  any other form of duplicative or unsolicited messages, whether
                  commercial or otherwise; (iii) use the Site to harvest,
                  collect, gather or assemble information or data regarding
                  other users, including e-mail addresses, without their
                  consent; (iv) interfere with, disrupt, or create an undue
                  burden on servers or networks connected to the Site, or
                  violate the regulations, policies or procedures of such
                  networks; (v) attempt to gain unauthorized access to the Site
                  (or to other computer systems or networks connected to or used
                  together with the Site), whether through password mining or
                  any other means; (vi) harass or interfere with any other
                  user’s use and enjoyment of the Site; or (vi) use software or
                  automated agents or scripts to produce multiple accounts on
                  the Site, or to generate automated searches, requests, or
                  queries to (or to strip, scrape, or mine data from) the Site
                  (provided, however, that we conditionally grant to the
                  operators of public search engines revocable permission to use
                  spiders to copy materials from the Site for the sole purpose
                  of and solely to the extent necessary for creating publicly
                  available searchable indices of the materials, but not caches
                  or archives of such materials, subject to the parameters set
                  forth in our robots.txt file).
                </p>
                <p className="title pt-3">
                  <span>3.5</span> <span className="ps-5">Feedback.</span> If
                  you provide Company with any feedback or suggestions regarding
                  the Site <span>(“Feedback”)</span>, you hereby assign to
                  Company all rights in such Feedback and agree that Company
                  shall have the right to use and fully exploit such Feedback
                  and related information in any manner it deems appropriate.
                  Company will treat any Feedback you provide to Company as
                  non-confidential and non-proprietary. You agree that you will
                  not submit to Company any information or ideas that you
                  consider to be confidential or proprietary.
                </p>
                <p className="title pt-3">
                  You agree to indemnify and hold Company (and its officers,
                  employees, and agents) harmless, including costs and
                  attorneys’ fees, from any claim or demand made by any third
                  party due to or arising out of (a) your use of the Site, (b)
                  your violation of these Terms, (c) your violation of
                  applicable laws or regulations or (d) your User Content.
                  Company reserves the right, at your expense, to assume the
                  exclusive defense and control of any matter for which you are
                  required to indemnify us, and you agree to cooperate with our
                  defense of these claims. You agree not to settle any matter
                  without the prior written consent of Company. Company will use
                  reasonable efforts to notify you of any such claim, action or
                  proceeding upon becoming aware of it.
                </p>
                <p className="title pt-3">
                  <span>5.</span>
                  <span className="ps-5">
                    Third-Party Links & Ads; Other Users
                  </span>
                </p>
                <p className="title pt-3">
                  <span>5.1 </span>{' '}
                  <span className="ps-5">Third-Party Links & Ads.</span> The
                  Site may contain links to third-party websites and services,
                  and/or display advertisements for third parties (collectively,{' '}
                  <span>“Third-Party Links & Ads”</span>). Such Third-Party
                  Links & Ads are not under the control of Company, and Company
                  is not responsible for any Third-Party Links & Ads. Company
                  provides access to these Third-Party Links & Ads only as a
                  convenience to you, and does not review, approve, monitor,
                  endorse, warrant, or make any representations with respect to
                  Third-Party Links & Ads. You use all Third-Party Links & Ads
                  at your own risk and should apply a suitable level of caution
                  and discretion in doing so. When you click on any of the
                  Third-Party Links & Ads, the applicable third party’s terms
                  and policies apply, including the third party’s privacy and
                  data gathering practices. You should make whatever
                  investigation you feel necessary or appropriate before
                  proceeding with any transaction in connection with such
                  Third-Party Links & Ads.
                </p>
                <p className="title pt-3">
                  <span>5.2</span>
                  <span className="ps-5">Other Users.</span> Each Site user is
                  solely responsible for any and all of its own User Content.
                  Because we do not control User Content, you acknowledge and
                  agree that we are not responsible for any User Content,
                  whether provided by you or by others. We make no guarantees
                  regarding the accuracy, currency, suitability, or quality of
                  any User Content. Your interactions with other Site users are
                  solely between you and such users. You agree that Company will
                  not be responsible for any loss or damage incurred as the
                  result of any such interactions. If there is a dispute between
                  you and any Site user, we are under no obligation to become
                  involved.
                </p>
                <p className="title pt-3">
                  <span>5.3</span>
                  <span className="ps-5">Release.</span> You hereby release and
                  forever discharge the Company (and our officers, employees,
                  agents, successors, and assigns) from, and hereby waive and
                  relinquish, each and every past, present and future dispute,
                  claim, controversy, demand, right, obligation, liability,
                  action and cause of action of every kind and nature (including
                  personal injuries, death, and property damage), that has
                  arisen or arises directly or indirectly out of, or that
                  relates directly or indirectly to, the Site (including any
                  interactions with, or act or omission of, other Site users or
                  any Third-Party Links & Ads). IF YOU ARE A CALIFORNIA
                  RESIDENT, YOU HEREBY WAIVE CALIFORNIA CIVIL CODE SECTION 1542
                  IN CONNECTION WITH THE FOREGOING, WHICH STATES: “A GENERAL
                  RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT
                  KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF
                  EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM OR HER MUST HAVE
                  MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR.”
                </p>
                <p className="title pt-3">
                  THE SITE IS PROVIDED ON AN “AS-IS” AND “AS AVAILABLE” BASIS,
                  AND COMPANY (AND OUR SUPPLIERS) EXPRESSLY DISCLAIM ANY AND ALL
                  WARRANTIES AND CONDITIONS OF ANY KIND, WHETHER EXPRESS,
                  IMPLIED, OR STATUTORY, INCLUDING ALL WARRANTIES OR CONDITIONS
                  OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE,
                  QUIET ENJOYMENT, ACCURACY, OR NON-INFRINGEMENT. WE (AND OUR
                  SUPPLIERS) MAKE NO WARRANTY THAT THE SITE WILL MEET YOUR
                  REQUIREMENTS, WILL BE AVAILABLE ON AN UNINTERRUPTED, TIMELY,
                  SECURE, OR ERROR-FREE BASIS, OR WILL BE ACCURATE, RELIABLE,
                  FREE OF VIRUSES OR OTHER HARMFUL CODE, COMPLETE, LEGAL, OR
                  SAFE. IF APPLICABLE LAW REQUIRES ANY WARRANTIES WITH RESPECT
                  TO THE SITE, ALL SUCH WARRANTIES ARE LIMITED IN DURATION TO
                  NINETY (90) DAYS FROM THE DATE OF FIRST USE.
                </p>
                <p className="title pt-3">
                  SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED
                  WARRANTIES, SO THE ABOVE EXCLUSION MAY NOT APPLY TO YOU. SOME
                  JURISDICTIONS DO NOT ALLOW LIMITATIONS ON HOW LONG AN IMPLIED
                  WARRANTY LASTS, SO THE ABOVE LIMITATION MAY NOT APPLY TO YOU.
                </p>
                <p className="title pt-3">
                  TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT SHALL
                  COMPANY (OR OUR SUPPLIERS) BE LIABLE TO YOU OR ANY THIRD PARTY
                  FOR ANY LOST PROFITS, LOST DATA, COSTS OF PROCUREMENT OF
                  SUBSTITUTE PRODUCTS, OR ANY INDIRECT, CONSEQUENTIAL,
                  EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES ARISING
                  FROM OR RELATING TO THESE TERMS OR YOUR USE OF, OR INABILITY
                  TO USE, THE SITE, EVEN IF COMPANY HAS BEEN ADVISED OF THE
                  POSSIBILITY OF SUCH DAMAGES. ACCESS TO, AND USE OF, THE SITE
                  IS AT YOUR OWN DISCRETION AND RISK, AND YOU WILL BE SOLELY
                  RESPONSIBLE FOR ANY DAMAGE TO YOUR DEVICE OR COMPUTER SYSTEM,
                  OR LOSS OF DATA RESULTING THEREFROM.
                </p>
                <p className="title pt-3">
                  TO THE MAXIMUM EXTENT PERMITTED BY LAW, NOTWITHSTANDING
                  ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR LIABILITY TO
                  YOU FOR ANY DAMAGES ARISING FROM OR RELATED TO THIS AGREEMENT
                  (FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE
                  ACTION), WILL AT ALL TIMES BE LIMITED TO A MAXIMUM OF FIFTY US
                  DOLLARS (U.S. $50). THE EXISTENCE OF MORE THAN ONE CLAIM WILL
                  NOT ENLARGE THIS LIMIT. YOU AGREE THAT OUR SUPPLIERS WILL HAVE
                  NO LIABILITY OF ANY KIND ARISING FROM OR RELATING TO THIS
                  AGREEMENT.
                </p>
                <p className="title pt-3">
                  SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF
                  LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE
                  ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU.
                </p>
                <p className="title pt-3">
                  <span>8.</span>
                  <span className="ps-5">Term and Termination.</span> Subject to
                  this Section, these Terms will remain in full force and effect
                  while you use the Site. We may suspend or terminate your
                  rights to use the Site (including your Account) at any time
                  for any reason at our sole discretion, including for any use
                  of the Site in violation of these Terms. Upon termination of
                  your rights under these Terms, your Account and right to
                  access and use the Site will terminate immediately. You
                  understand that any termination of your Account may involve
                  deletion of your User Content associated with your Account
                  from our live databases. Company will not have any liability
                  whatsoever to you for any termination of your rights under
                  these Terms, including for termination of your Account or
                  deletion of your User Content. Even after your rights under
                  these Terms are terminated, the following provisions of these
                  Terms will remain in effect: Sections 2.2 through 2.5, Section
                  3 and Sections 4 through 10.
                </p>
                <p className="title pt-3">
                  <span> 9. </span>
                  <span className="ps-5">Copyright Policy.</span>
                </p>
                <p className="title pt-3">
                  Company respects the intellectual property of others and asks
                  that users of our Site do the same. In connection with our
                  Site, we have adopted and implemented a policy respecting
                  copyright law that provides for the removal of any infringing
                  materials and for the termination, in appropriate
                  circumstances, of users of our online Site who are repeat
                  infringers of intellectual property rights, including
                  copyrights. If you believe that one of our users is, through
                  the use of our Site, unlawfully infringing the copyright(s) in
                  a work, and wish to have the allegedly infringing material
                  removed, the following information in the form of a written
                  notification (pursuant to 17 U.S.C. § 512(c)) must be provided
                  to our designated Copyright Agent:
                </p>
                <p className="title pt-3 pb-4">
                  <ol>
                    <li>your physical or electronic signature;</li>
                    <li>
                      identification of the copyrighted work(s) that you claim
                      to have been infringed;
                    </li>
                    <li>
                      identification of the material on our services that you
                      claim is infringing and that you request us to remove
                    </li>
                    <li>
                      sufficient information to permit us to locate such
                      material;
                    </li>
                    <li>your address, telephone number, and e-mail address;</li>
                    <li>
                      a statement that you have a good faith belief that use of
                      the objectionable material is not authorized by the
                      copyright owner, its agent, or under the law; and
                    </li>
                    <li>
                      a statement that the information in the notification is
                      accurate, and under penalty of perjury, that you are
                      either the owner of the copyright that has allegedly been
                      infringed or that you are authorized to act on behalf of
                      the copyright owner.
                    </li>
                  </ol>
                </p>
                <p className="title pt-3 pb-4">
                  Please note that, pursuant to 17 U.S.C. § 512(f), any
                  misrepresentation of material fact (falsities) in a written
                  notification automatically subjects the complaining party to
                  liability for any damages, costs and attorney’s fees incurred
                  by us in connection with the written notification and
                  allegation of copyright infringement.
                </p>
                <p className="title pt-3">
                  The designated Copyright Agent for Company is: __Burr Forman
                  LLP_______
                </p>
                <p className="title pt-3">
                  Designated Agent: ____Doug Lineberry_____
                </p>
                <p className="title pt-3">
                  Address of Agent: ___104 South Main Street, Suite 700
                  Greenville, SC 29601_____
                </p>
                <p className="title pt-3">Telephone: ___864-271-4940______</p>
                <p className="title pt-3 pb-4">
                  Email: ___dlineberry@burr.com______
                </p>
                <p className="title pt-3">
                  For clarity, By accepting the receipt of Electronic
                  Transmissions, you voluntarily agree to have all records,
                  including your current and future insurance related documents,
                  provided to you in electronic form. Insurance related
                  documents include, but are not limited to:
                </p>
                <p className="title pt-3">
                  We will use various methods to provide communications to you
                  electronically, including via e-mail or through our website or
                  app. For this second option, you may need to log-in to access
                  the information.
                </p>
                <p className="title pt-3">
                  This consent, unless withdrawn, applies to all transactions
                  between you and Right to bear. However, you have the right to
                  receive communications from us, including the insurance
                  policy, in paper form if you wish by withdrawing this consent.
                  If you withdraw the consent to provide you with records in
                  electronic form, you will then be provided with records in
                  paper form at no charge.
                </p>
                <p className="title pt-3">
                  To change or correct your email address, you can email us at
                  help@righttobear.com with the following subject line
                  &quot;EMAIL ADDRESS UPDATE&quot;. The body of the email must
                  include your name, policy number, the start date of the
                  policy, named insured’s date of birth and the updated email
                  address. Note our website address is www.righttobear.com.
                  <span>Entire Terms</span>. These Terms constitute the entire
                  agreement between you and us regarding the use of the Site.
                  Our failure to exercise or enforce any right or provision of
                  these Terms shall not operate as a waiver of such right or
                  provision. The section titles in these Terms are for
                  convenience only and have no legal or contractual effect. The
                  word “including” means “including without limitation”. If any
                  provision of these Terms is, for any reason, held to be
                  invalid or unenforceable, the other provisions of these Terms
                  will be unimpaired, and the invalid or unenforceable provision
                  will be deemed modified so that it is valid and enforceable to
                  the maximum extent permitted by law. Your relationship to
                  Company is that of an independent contractor, and neither
                  party is an agent or partner of the other. These Terms, and
                  your rights and obligations herein, may not be assigned,
                  subcontracted, delegated, or otherwise transferred by you
                  without Company’s prior written consent, and any attempted
                  assignment, subcontract, delegation, or transfer in violation
                  of the foregoing will be null and void. Company may freely
                  assign these Terms. The terms and conditions set forth in
                  these Terms shall be binding upon assignees.
                </p>
                <p className="title pt-3">
                  <span>Customer Support</span>
                </p>
                <p className="title pt-3">
                  Address: 1680-B Lake Murray Blvd Columbia SC 29212
                </p>
                <p className="title pt-3">Telephone: 803-233-7681</p>
                <p className="title pt-3">Email: info@protectwithbear.com</p>
                <p className="title-name">
                  These insurance-related notices provide additional information
                  relevant to the Products provided by Right to Bear. In the
                  event and to the extent these notices conflict with any
                  federal or state laws or regulations, the latter shall govern.
                </p>
                <p className="title-name">
                  Your Representations and Warranties
                </p>
                <p className="title-name">You represent and warrant that:</p>
                <p className="title">
                  In the case of a life insurance policy, these statements are
                  deemed representations and not warranties, to the extent
                  required under applicable law.
                </p>
                <p className="title pt-3">
                  <span>11.1</span>
                  <span className="ps-5">Website Assistant</span>
                </p>
                <p className="title">
                  Any insurance purchasing conclusions and decisions such as
                  coverage amounts, limits and deductibles are completely and
                  solely the responsibility of the insured. Right to Bear may at
                  its sole discretion change the Website’s content and
                  information from time to time. At the time of a claim,
                  coverage and benefits will be determined in accordance with
                  the terms and conditions of your insurance policy.
                </p>
                <p className="title pt-3">
                  <span>11.2 </span>
                  <span className="ps-5">
                    Credit and Credit-Based Information
                  </span>
                </p>
                <p className="title pt-3">
                  Disclosure in accordance with the Insurance Law
                </p>
                <p className="title pt-3">
                  If you are a first time user please read the following
                  disclosure: In connection with this insurance, we may review
                  your credit report or obtain or use a credit-based insurance
                  score, based on information contained in that report. We will
                  not review your credit report or obtain or use a credit-based
                  insurance score in states where this is prohibited. An
                  insurance score uses information from your credit report to
                  help predict how often you are likely to file claims and how
                  expensive those claims will be. Typical items from a credit
                  report that could affect a score include, but are not limited
                  to, the following: payment history, number of revolving
                  accounts, number of new accounts, the presence of collection
                  accounts, bankruptcies and foreclosures. The information used
                  to develop the insurance score comes from TransUnion.
                </p>
                <p className="title pt-3">
                  If you used our services before and you wish to renew your
                  policy please read the following:
                </p>
                <p className="title pt-3">
                  In connection with this insurance, we may have previously used
                  a credit report or obtained or used a credit-based insurance
                  score based on information contained in that report. We may
                  obtain or use credit information provided again, upon renewal
                  of policy. We will not review your credit report or obtain or
                  use a credit-based insurance score in states where this is
                  prohibited. An insurance score uses information from your
                  credit report to help predict how often you are likely to file
                  claims and how expensive those claims will be. Typical items
                  from a credit report that could affect a score include, but
                  are not limited to, the following: payment history, number of
                  revolving accounts, number of new accounts, the presence of
                  collection accounts, bankruptcies and foreclosures. The
                  information used to develop the insurance score comes from
                  TransUnion.
                </p>
                <p className="title pt-3">
                  11.3{' '}
                  <span className="changes">
                    Recurring Credit/Debit Card Payments
                  </span>
                </p>
                <p className="title pt-3">
                  You understand and agree that we will charge the credit or
                  debit card you use to purchase an insurance policy from us.
                  Thereafter, we will automatically charge the card monthly for
                  the monthly premium, the amount of which may change depending
                  on policy changes you initiate and other factors approved by
                  insurance regulatory authorities. Your policy is a contract
                  for a set period; 12 months. Your card will automatically be
                  charged for the monthly premium if your policy is on a monthly
                  payment plan. We will do the same for any subsequent renewals.
                  You may cancel these recurring payments by cancelling your
                  policy on the Site. You may also cancel your policy by
                  emailing our customer experience team at{' '}
                  <a href="mailto:info@protectwithbear.com">
                    info@protectwithbear.com
                  </a>
                </p>
                <p className="title pt-3">
                  11.4{' '}
                  <span className="changes">
                    Insurance Quotes and Coverages
                  </span>
                </p>
                <p className="title pt-3">
                  All quotes generated by Right to Bear platform are based upon
                  the information you provided and are not a contract, binder,
                  or agreement to extend insurance coverage. Any coverage
                  descriptions provided on our website are general descriptions
                  of available coverages and are not a statement of contract. To
                  obtain coverage you must submit an application to Right to
                  Bear whether on Right to Bear’s web site, mobile app or
                  otherwise. All applications are subject to underwriting
                  approval. Coverages and availability may vary by state or
                  province; and additional minimum coverage limits may be
                  available in your state.
                </p>
                <p className="title pt-3">
                  <span>Information about DMCA Safe</span>
                </p>
                <p className="title pt-3">Harbor:</p>
                <p className="title pt-3 mb-5">
                  The Copyright Policy section (Section 9 above) has been
                  included because you indicated that righttobear.com includes
                  user content. We recommend you take advantage of the DMCA safe
                  harbor.
                </p>
                <p className="title pt-3 mb-5">
                  The DMCA provides certain “safe harbor” provisions that
                  insulate online service providers (OSP) from liability for
                  copyright infringement for infringing activities of its end
                  users. In addition to meeting the requirements for each safe
                  harbor exemption, an OSP must comply with the following to
                  qualify for the safe harbor protection under the DMCA: (a)
                  Give notice to its users of its policies regarding copyright
                  infringement and the consequences of repeated infringing
                  activity. (b) Follow proper notice and takedown procedures.
                  Once a copyright owner provides proper notice of allegedly
                  infringing material to the OSP, or the OSP discovers such
                  material itself, the OSP must remove, or disable access to,
                  the material; provide notice thereafter to the individual
                  responsible for such material; provide such individual with an
                  opportunity to provide proper “counter-notice;” and comply
                  with applicable procedures thereafter. (c) Designate an agent
                  to receive notices of infringement from copyright owners (and
                  provide the Copyright Office with contact information for such
                  agent and make such information available on its website).
                </p>
                <p className="title pt-3 mb-5">
                  See:{' '}
                  <a href="https://www.copyright.gov/dmca-directory/">
                    http://www.copyright.gov/onlinesp/
                  </a>{' '}
                  for the applicable forms and more information.
                </p>
              </div>
            </div>
          </div>
        </div>
      </MainLayout>
    </>
  );
};

export default TermsConditions;
