import React from 'react';
import PropTypes from 'prop-types';

const NumberCard = (props) => {
  return (
    <>
      <div className="card-number">
        <h4 className="number">{props.Number}</h4>
        <p className="card-number-text">{props.Heading}</p>
      </div>
    </>
  );
};

NumberCard.propTypes = {
  Heading: PropTypes.string,
  Number: PropTypes.string,
};

export default NumberCard;
