import React from 'react';
import MainLayout from '../Layout/MainLayout';
import NumberCard from '../components/NumberCard';

const CarryResponsibly = () => {
  return (
    <>
      <MainLayout>
        <div className="about-banner carry">
          <div className="about-outer">
            <h1 className="banner-heading">CARRY RESPONSIBLY</h1>
          </div>
        </div>
        <div className="carry-responsibly-user">
          <div className="container">
            <div className="row">
              <div className="carry-user-gun">
                <div className="carry-title">
                  <h3 className="carry-heading">
                    Right to Bear does not condone unnecessary or excessive
                    force, or illegal acts of aggression.
                  </h3>
                  <p>
                    We want to help you stay safe, legal, and prepared as you
                    exercise your Second Amendment right to keep and bear arms.
                  </p>
                  <p>
                    The right to keep and bear arms, to protect ourselves, our
                    loved ones, and the innocent and vulnerable in our
                    communities deserves to be steadfastly protected. We believe
                    that education on local laws, procedures, and proper use of
                    our firearms is a key and vital component of defending those
                    rights.
                  </p>
                  <p>
                    As firearm owners, we pride ourselves on accepting the added
                    responsibility that comes along with gun ownership.
                  </p>
                  <p>
                    We know that safety and security are paramount principles.
                    That&apos;s why we are gun owners in the first place.
                  </p>
                  <ul>
                    <li>
                      We at Right To Bear promote freedom and the safe and legal
                      usage of firearms by law abiding citizens. The
                      self-defense protection we sell does not protect you in
                      cases of unnecessary or excessive force, or illegal acts
                      of aggression. We protect responsible gun owners that are
                      forced to use their firearm in cases of self-defense.
                    </li>
                  </ul>
                  <p>
                    We are seeing the largest number of background check
                    submissions to the FBI than ever before. Background checks
                    have doubled in the last decade from 2011 levels. Over
                    100,000 background checks PER DAY is the new norm. In 2020,
                    there were over 20 million legal firearms sold in the US,
                    with estimates of approximately 40% being first time gun
                    buyers.
                  </p>
                  <p>
                    With a projection of over 2 million home
                    break-ins/burglaries in 2021, and year over year increases
                    of 12% in violent crime and 30% in murder incidents, it has
                    never been more important that responsible gun owners are
                    fully prepared to protect themselves.
                  </p>
                </div>
              </div>
              <img
                src="../../images/line.svg"
                alt="line"
                className="img-fluid line-image"
              />
              <div className="col-12">
                <h3 className="sub-title sub-text-m">4 RULES OF GUN SAFETY</h3>
              </div>
              <div className="col-md-3">
                <NumberCard
                  Number="1"
                  Heading="Treat every firearm as if it were loaded."
                />
              </div>
              <div className="col-md-3">
                <NumberCard
                  Number="2"
                  Heading="Always keep the firearm pointed in a safe direction."
                />
              </div>
              <div className="col-md-3">
                <NumberCard
                  Number="3"
                  Heading="Keep your finger off the trigger until ready to shoot."
                />
              </div>
              <div className="col-md-3">
                <NumberCard
                  Number="4"
                  Heading="Always know what your target is and what lies beyond it."
                />
              </div>
            </div>
          </div>
        </div>
      </MainLayout>
    </>
  );
};

export default CarryResponsibly;
