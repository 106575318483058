import React from 'react';
import MainLayout from '../Layout/MainLayout';
import Accordion from '../components/Accordion';
import CustomerService from '../components/CustomerService';

const Support = () => {
  return (
    <>
      <MainLayout>
        <div className="box-card-price">
          <div className="container">
            <div className="row">
              <div className="col-md-6 mb-3">
                <CustomerService
                  Title="Give Us a Call:"
                  Customer="Customer Service"
                  Number="833-887-2327"
                  EmergencyTitle="Attorney Hotline Emergency Number"
                  EmergencyNumber="888-363-1671"
                />
              </div>

              <div className="col-md-6 mb-3">
                <div className="first-card-price">
                  <h3 className="call">Contact Emails:</h3>
                  <div className="service">
                    <h3>General</h3>
                    <p>
                      <strong>
                        <a href="mailto:info@protectwithbear.com">
                          info@protectwithbear.com
                        </a>
                      </strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Accordion heading="LOOKING FOR INFORMATION?" />
      </MainLayout>
    </>
  );
};

export default Support;
