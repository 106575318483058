import React from 'react';
import MainLayout from '../Layout/MainLayout';

const OpenCarry = () => {
  return (
    <>
      <MainLayout>
        <div className="personal-gun">
          <div className="container">
            <div className="row">
              <div className="insurance-gun-user">
                <img src="../../images/blog-2.webp" alt="" />
              </div>
              <div className="handgun">
                <h3 className="handgun-title">
                  To Open Carry or Concealed Carry?
                </h3>
                <p className="handgun-sub-title">MARCH 17, 2023</p>
              </div>
              <div className="protection">
                <a href="#" className="share-link">
                  <img src="../../images/share.svg" alt="" />
                  Share
                </a>
                <p className="owning-text">
                  There are a lot of opinions when it comes to the debate
                  between open carry versus concealed carry. Heck, I have plenty
                  of my own. However, I think the decision can be quickly
                  informed by looking at the application context.
                </p>
                <p className="gun-link-firearm">
                  That is the question. Okay, maybe not “the” question, but it
                  is a question that is asked often and it often leads to a lot
                  of discussion which sometimes gets a little heated. The points
                  made for and against each carry methods most often than not
                  have a lot of merit, but often the points are made without
                  full context or without applicable context. I might sound like
                  a broken record, but context is everything.
                </p>
                <p className="gun-link-firearm">
                  Another good question to ask yourself before you decide to
                  carry is, “Am I prepared for the legal battle, both criminal
                  and civil, that lies ahead, if I have to defend myself or a
                  loved one against attack?” Do you have the financial resources
                  to hire the best self defense attorney, or will you be stuck
                  with a Public Defender? Before you plan to carry, you need a
                  plan to help you out in the aftermath of a self defense
                  situation. Right To Bear Legal Defense
                  (www.protectwithbear.com) is the most value centered, cost
                  effective, self defense legal protection in America. For less
                  than 50 cents a day, you can have a seasoned, self defense
                  attorney, on speed dial, 24 hours a day, 7 days a week, if
                  you’re ever involved in a self defense use of force situation.
                  Unlike some other companies, Right To Bear has UNLIMITED
                  coverage for one low price and you’ll never have to pay back
                  any of your legal fees. Check out Right To Bear’s coverage at{' '}
                  <a href="/" className="text-change">
                    www.protectwithbear.com
                  </a>{' '}
                  today.
                </p>
                <p className="gun-link-firearm">
                  I’m going to come right out of the gate and say it. In the
                  context of an armed civilian who is carrying a pistol for
                  defense against criminal assailants, I firmly believe
                  concealed carry is the way to go. I’m leading with this
                  because the vast majority of folks who are debating the merits
                  of open versus concealed carry do so in this context. There
                  are certainly times when I will open carry a pistol, but the
                  contexts are different and the parameters of those contexts
                  inform everything including the selection of the pistol, the
                  ammunition, and the carry method. I’ll provide some examples
                  of this towards the end of the post, but first let’s look at
                  some of the common discussion points in the context of the
                  armed civilian and criminal assailants.
                </p>
                <p className="gun-link-firearm">
                  The topic of comfort always seems to come up in these
                  discussions with the argument being that open carry is simply
                  more comfortable that concealed carry. I agree with this
                  statement. I find open carry to be more comfortable than
                  concealed carry myself. However, what does comfort have to do
                  with being properly equipped to respond to a deadly threat
                  from a criminal assailant? I suppose one could argue that
                  comfort plays a role in encouraging an individual to carry
                  their firearm. That’s a weak argument though. If I recall
                  correctly I heard Tom Givens made an analogy equating carrying
                  a concealed firearm to wearing shoes in the sense that none of
                  us were born with shoes on and when our first pair of shoes
                  were donned we immediately attempted to remove them because we
                  weren’t acclimatized to them. Carrying a concealed firearm is,
                  in my opinion, more noticeable than open carrying a firearm.
                  However, it doesn’t take long for one to acclimatize to
                  carrying a concealed firearm to the point to where not
                  carrying one feels off. Much like not wearing shoes when
                  accustomed to wearing them.
                </p>

                <p className="gun-link-firearm">
                  Another point of debate is that drawing from open carry is
                  faster than drawing from concealed carry. Okay, that’s true,
                  but is the speed difference significant enough to really
                  matter in the context of defense against criminal assailants?
                  Furthermore, there is a difference in draw time between an
                  outside the waistband (OWB) holster without active retention
                  and an OWB holster with active retention mechanisms (what is
                  typically referred to as a duty holster). The lack of active
                  retention mechanisms on an OWB holster used for this context
                  gives me a lot of pause and I’ll get into this shortly, but
                  for now let’s consider draw speed. Assuming one has achieved a
                  minimum level of competency with their defensive pistol, the
                  difference in draw time from an OWB holster without active
                  retention mechanisms and an inside the waistband (IWB) holster
                  that is concealed with closed front garment is somewhere
                  between 0.25 and 0.5 seconds. Will fractions of a second make
                  the difference between success or failure in a self defense
                  encounter? Possibly, but unlikely. The additional time also
                  gives a self defender more room to decide whether or not to
                  fire the initial shot and there is a lot of value in that as
                  the presentation of a firearm has a high likelihood in
                  psychologically stopping a criminal assailant. In my opinion,
                  it’s better to stop an assailant without firing a shot as it
                  carries less legal and financial risk in the aftermath of an
                  encounter to a defender assuming the defender was legally
                  justified in presenting a firearm. Beyond that there are a lot
                  of contextual factors that are far more important when making
                  the decision to draw than the fractions of a seconds gained
                  from an open carry set up. In other words, I consider the
                  speed gain from open carry point to be moot and irrelevant in
                  this context.
                </p>
                <p className="gun-link-firearm">
                  Every now and then someone points out that a visible firearm
                  worn in a respectable fashion can lead to conversations about
                  the Second Amendment and may help convert folks who are
                  ambivalent towards the gun rights issues to be for the
                  preservation (or expansion) of gun rights. I consider the
                  notion behind this opinion to be noble and have good
                  intentions. Yet I fail to see the relevance to context. Now if
                  the context goal is to raise awareness of gun rights issues,
                  then that’s one thing. However from a day to day armed
                  civilian lifestyle context, it seems to me like one is giving
                  up some self defense advantages for a secondary cause and
                  that’s not something I would personally do nor suggest others
                  do.
                </p>
                <p className="gun-link-firearm">
                  “A visible gun serves as a deterrent.” I think this is a
                  terrible point based on a dangerous assumption. That
                  assumption is that criminals think like you or I do. Perhaps
                  Massad Ayoob said it best, “the gun is not a magic talisman
                  that wards off evil.” Criminals, for the most part, are
                  opportunists. They also don’t value life nor respect property
                  the same way folks of good moral character do. For some
                  criminals, the visible gun is a possible reward worth taking
                  given an opportunity with an acceptable level of risk. That
                  type of opportunity is more often than not a moment where the
                  potential victim is not paying attention. This means the best
                  deterrent we have isn’t a visible firearm, but rather visible
                  situational awareness. Combining the potential of a criminal
                  deciding they want the firearm with a momentary lapse in
                  situational awareness is what gives me the most pause about
                  openly carrying a firearm in an OWB holster without active
                  retention mechanisms. There have been enough{' '}
                  <a
                    href="https://www.youtube.com/c/ActiveSelfProtection/search"
                    className="text-change"
                  >
                    documented cases of this happening
                  </a>{' '}
                  for me to consider this a risk that can be mitigated with an
                  OWB holster with active retention mechanisms and is better
                  mitigated with concealed carry because the firearm isn’t
                  advertised.
                </p>
                <p className="gun-link-firearm">
                  While concealed carry may take more effort to be acclimatized
                  to, makes drawing the pistol a little slower, and may result
                  in the loss of gun rights conversation opportunities, it does
                  provide one additional advantage beyond not making us a target
                  for a potential firearm theft attempt that cannot be
                  understated. That advantage is the element of surprise. One
                  thing the armed citizen can be fairly certain of is that the
                  criminal isn’t playing to lose and they will use every
                  advantage they can to achieve their goal, but they are human
                  like rest of us and as a result fallible. While criminals are
                  opportunists who don’t think the same way we do, they aren’t
                  any more or less stupid than other people. They also have
                  common expectations and have experienced a variation of social
                  conditioning like the rest of us. In other words, once they
                  have selected their victim and decided to act they are
                  carrying out a plan with one or more premade decisions and a
                  clear set of expectations. A common expectation is that they
                  expect compliance. After the compliance occurs they have
                  already decided what they will do next (of course they don’t
                  always advertise that and if they do the advertisement may be
                  false). To one extent or another they may also expect some
                  level of resistance. With a visible firearm, they will
                  undoubtedly have some thoughts about what to do if the victim
                  reaches for it. Or they may have decided to neutralize the
                  victim before taking what they want. Regardless a concealed
                  firearm is difficult to plan for and when it is presented the
                  surprise disrupts the flow of the plan. That disruption is a
                  very powerful event that greatly increases the odds of
                  successfully defending one’s self and loved ones. When to
                  bring a concealed firearm into an encounter is also very
                  important and depends entirely on the dynamics of the
                  encounter. As such, it is beyond the scope of this post and
                  maybe a topic for a future post
                </p>
                <p className="gun-link-firearm">
                  At the beginning of this post, I promised to bring up some
                  other contexts as examples when open carry makes more sense to
                  me. The first context is for defense where the most likely
                  threat is some form of wildlife. In this context, the element
                  of surprise from concealed carry and the potential for firearm
                  theft are both moot. Furthermore, the presentation of a
                  firearm alone is not likely going to result in a psychological
                  stop. For these reasons, I think open carry makes more sense
                  and doesn’t necessitate the use of a holster with active
                  retention mechanisms.
                </p>

                <p className="gun-link-firearm">
                  Competitive shooting sports is another context where open
                  carry makes sense. In this context, speed measured in
                  fractions of a second can mean the difference between a win or
                  a loss. The rules of the competition will dictate holster
                  requirements and carry methods. While deep concealment with an
                  IWB holster may be allowed in the rules, it puts the
                  competitor at a disadvantage against others with similar skill
                  levels who opt for using an OWB holster without active
                  retention mechanisms and with passive retention adjusted to
                  the minimum retention allowed by the rule book.
                </p>
                <p className="gun-link-firearm">
                  I hope that it’s become clear that context is everything when
                  answering the question, “to open carry or concealed carry?”
                  Based on all the evidence I’ve seen, the training I’ve
                  received, and the authored content from authoritative sources
                  I’ve consumed, I continue to think that concealed carry is the
                  way to go in the context of an armed citizen defending against
                  criminal assailants. Whether or not individual readers agree
                  with me, I think it’s important that the carry method one opts
                  for is a well informed decision.
                </p>
                <p className="gun-link-firearm">
                  Originally posted at
                  https://unclezo.com/2022/09/20/to-open-carry-or-concealed-carry/
                </p>

                <div className="blog-back">
                  <a href="/righttoblog">
                    <i className="fa fa-long-arrow-left"></i>
                    Back to blog
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainLayout>
    </>
  );
};

export default OpenCarry;
