import React from 'react';
import MainLayout from '../Layout/MainLayout';
import Payment from './Payment';

const CheckOut = () => {
  return (
    <>
      <MainLayout>
        <Payment />
      </MainLayout>
    </>
  );
};

export default CheckOut;
