import React from 'react';
import MainLayout from '../Layout/MainLayout';

const QualitiesDefensiveHandgun = () => {
  return (
    <>
      <MainLayout>
        <div className="personal-gun">
          <div className="container">
            <div className="row">
              <div className="insurance-gun-user">
                <img src="../../images/custom_resized.webp" alt="" />
              </div>
              <div className="handgun">
                <h3 className="handgun-title">
                  Qualities of a Defensive Handgun
                </h3>
                <p className="handgun-sub-title">JUNE 23, 2023</p>
              </div>
              <div className="protection">
                <a href="#" className="share-link">
                  <img src="../../images/share.svg" alt="" />
                  Share
                </a>
                <p className="owning-text">
                  Not all handguns are designed with self defense in mind and
                  some that are aren’t particularly well suited for it. Knowing
                  what qualities to look for in a defensive handgun helps
                  discern the good from not so good.
                </p>
                <p className="gun-link-firearm">
                  Over the past few years, I’ve invested a sizable amount of
                  time and money into becoming better versed in armed self
                  defense. As a result, my perspective has evolved and I’d like
                  to believe that my approach to handgun selection has become
                  significantly more objective and less subjective. Given the
                  frequency of questions I receive asking for pistol suggestions
                  or comparisons, I figured this would be a good time to put
                  something together that describes the qualities of a defensive
                  handgun. These are the qualities that one should consider when
                  selecting a handgun that one may depend on in the most dire
                  circumstances. Let’s look at them.
                </p>
                <p className="gun-link-firearm">
                  But before we compare handguns, I also want to share with you
                  that self defense legal plans are not all the same either. Why
                  should you get a legal defense plan? Are you prepared for the
                  legal battle, both criminal and civil, that lies ahead? Do you
                  have the financial resources to hire the best self defense
                  attorney, or will you be stuck with a Public Defender? Before
                  you plan to carry, you need a plan to help you out in the
                  aftermath of a self defense situation. Right To Bear Legal
                  Defense (www.protectwithbear.com) is the most value centered,
                  cost effective, self defense legal protection in America. For
                  less than 50 cents a day, you can have a seasoned, self
                  defense attorney, on speed dial, 24 hours a day, 7 days a
                  week, if you’re ever involved in a self defense use of force
                  situation. Unlike some other companies, Right To Bear has
                  UNLIMITED coverage for one low price and you’ll never have to
                  pay back any of your legal fees. Check out Right To Bear’s
                  coverage at{' '}
                  <a href="/" className="text-change">
                    www.protectwithbear.com
                  </a>{' '}
                  today.
                </p>
                <p className="owning-text">Reliability</p>
                <p className="gun-link-firearm">
                  It really doesn’t matter who you ask about what to look for in
                  a defensive handgun. Virtually everyone, assuming they have
                  some knowledge of armed self defense, will lead off with
                  reliability as the most important quality a defensive handgun
                  has to have. The reason for this is quite simple: the gun has
                  to go bang when one’s life is depending on it. Reliability is
                  also why the same pool of makes and models of firearms are
                  suggested over and over again as a good place to start. That
                  pool is filled with firearms that have a track record for
                  reliability.
                </p>
                <p className="gun-link-firearm">
                  A common thing I see, which I think is a mistake, is that many
                  folks who pick up a gun with a reputation for reliability
                  simply assume it will be reliable and don’t confirm it for
                  themselves. I have more than a few problems with this. Here is
                  the thing, manufacturing defects happen regardless of how
                  stellar the quality controls are. Given the important role
                  that this firearm may one day serve, it behooves everyone to
                  put a sufficient number of rounds downrange to ensure their
                  specific firearm will run reliably. How many rounds is
                  sufficient? There is no exact number that I am aware of, but I
                  suggest a minimum of 500 rounds of which 100 are the high
                  quality self defense rounds that have been selected for that
                  firearm. I get that that number seems daunting or expensive to
                  some folks, but it’s not something that has to happen in a
                  single range trip. It can and should happen naturally over
                  time as the gun is taken to the range for practice or to
                  training courses which are necessary to become familiar with
                  the firearm and become proficient with it.
                </p>
                <p className="gun-link-firearm">
                  The less discussed aspect of reliability deals with the gun
                  not going bang when it’s not supposed to. In other words, the
                  safety features of the firearm must also be reliable. Safety
                  features vary from handgun to handgun, but for the most part
                  all modern handguns are drop safe and include a combination of
                  passive and active safety mechanisms. Some of the safety
                  mechanisms can and should be functionally tested when
                  performing typical firearm maintenance. Again, assuming the
                  safety mechanisms will work reliably is not a good idea. Other
                  safety mechanisms, such as those implemented to make a handgun
                  drop safe, are not so easily tested. For this reason, I’m not
                  keen on opting for new firearms or firearm variants that have
                  just been introduced to the marketplace since there could be
                  some flaws that have yet to be identified and worked out. A
                  good example of this is the safety issues that were found with
                  the Sig Sauer P320 when it was first introduced and resulted
                  in a voluntary safety recall.
                </p>
                <p className="gun-link-firearm">
                  Bottom line is a defensive handgun must be reliable. This
                  quality should be continuously verified and that should happen
                  naturally as part of cultivating proficiency with the handgun
                  and performing regular maintenance. Taking a page out of Tom
                  Givens’ book,{' '}
                  <a href="https://www.amazon.com/Concealed-Carry-Class-Self-Defense-Tactics/dp/1946267953?keywords=tom+givens+concealed+carry+class&qid=1661967058&sprefix=tom+given,aps,102&sr=8-1&linkCode=sl1&tag=unclezo-20&linkId=7a2109063e4d2f633a9a0930cc09d423&language=en_US&ref_=as_li_ss_tl">
                    Concealed Carry Class
                  </a>
                  : if at any time the handgun exhibits signs of reliability
                  issues, such as malfunctions during practice or safety feature
                  failures during maintenance, it should be either fixed or
                  replaced.
                </p>
                <p className="owning-text">Effective</p>
                <p className="gun-link-firearm">
                  <a href="https://www.activeresponsetraining.net/">
                    Greg Ellifritz
                  </a>{' '}
                  has conducted extensive{' '}
                  <a href="https://www.buckeyefirearms.org/node/7866">
                    research regarding the effectiveness of handgun cartridges
                  </a>{' '}
                  in stopping attacks from predators of the human variety. There
                  are two interesting measurements for each cartridge studied in
                  his findings that I find very telling. They are the percentage
                  of attackers who continued their attack regardless of how many
                  hits were delivered and the percentage of attackers who
                  stopped their attack after a single hit. The percentage of
                  non-stops was nearly double with smaller less powerful popular
                  handgun cartridges when compared to larger more powerful
                  counterparts. The percentage of one-shot-stops generally
                  trended upwards as the handgun cartridges got larger and more
                  powerful. These numbers tell me that there is a point where
                  cartridges are too anemic to be counted on to reliably
                  penetrate an attacker’s vital anatomical structures reliably
                  to be considered effective for defensive use.
                </p>
                <p className="gun-link-firearm">
                  What are good options? Basically, any popular handgun
                  cartridge larger and more powerful than .32 ACP can be
                  considered effective for defense against predators of the
                  human variety since that’s where the non-stop percentage rate
                  is cut in half. Examples of popular options include, but are
                  not limited to:
                </p>
                <p className="gun-link-firearm pt-0 mb-0">
                  <ul className="dote">
                    <li>.380 ACP (also known as .380 Auto)</li>
                    <li>.38 Special</li>
                    <li>9mm Luger (also known as 9x19mm or 9mm Parabellum)</li>
                    <li>.357 Sig</li>
                    <li>.357 Magnum</li>
                    <li>.40 S&W</li>
                    <li>.45 ACP (also known as .45 Auto)</li>
                    <li>.44 Magnum</li>
                  </ul>
                </p>
                <p className="owning-text">Portable</p>
                <p className="gun-link-firearm">
                  There are different terms for this quality used by different
                  folks. Some folks will use the term “concealable” and others
                  will use the term “wearable”. Regardless of the term used, the
                  main point of this quality is that size of the gun should
                  allow for program compliance. That is the gun should lend
                  itself to be carried on one’s person regularly. Ideally every
                  single day as a person goes on about their business since when
                  and where it may be needed is not something that can be
                  predicted.
                </p>
                <p className="gun-link-firearm">
                  As straightforward as that this quality may seem, there are
                  some aspects of it that are either overlooked or considered
                  with a strong bias. This is especially true of folks who are
                  new to carrying a defensive handgun. More often than not I see
                  a strong bias for the smallest and lightest handgun with the
                  idea that it will be easy to conceal and comfortable to carry.
                  This bias overlooks that smaller and lighter pistols are
                  generally more difficult to shoot well and are limited in
                  capacity making them far more suitable as a backup handgun
                  rather than a defensive handgun. Perhaps the worst side effect
                  is that most of these guns are unpleasant to shoot and that
                  leads to a lack of developed proficiency.
                </p>
                <p className="gun-link-firearm">
                  On occasion I see a strong bias for full size pistols with the
                  idea to maximize capacity and ease of use without considering
                  that their day to day lifestyle doesn’t lend itself to
                  carrying the selected handgun (this is most often an effect of
                  dress code requirements and permissiveness of the
                  environment). This leads to the defensive handgun spending
                  more time in the safe at home than on the person.
                </p>
                <p className="gun-link-firearm">
                  There is no silver bullet here. Some folks might be able to
                  find a one size fits all handgun. Others will have to opt for
                  a one size fits most handgun and may have to rely on one or
                  more handguns to deal with the scenarios where the one size
                  fits most option doesn’t work. Either way, the point is to
                  land the best option that enables habitual carry and
                  facilitates proficiency
                </p>
                <p className="owning-text">Ease of Use</p>
                <p className="gun-link-firearm">
                  The ease of use quality is, in my opinion, the most nuanced
                  quality and the most difficult for newer pistol shooters to
                  discern and evaluate. At its core, this quality is about how
                  well a handgun lends itself to being operated efficiently and
                  effectively under an incredible amount of stress. There are
                  two aspects to this. The first aspect deals with the handguns
                  design. The second aspect concerns how the individual
                  interfaces with the handgun – that is{' '}
                  <a href="https://unclezo.com/2021/06/29/what-is-gun-fit/">
                    {' '}
                    how well the gun fits the individual.
                  </a>
                </p>
                <p className="gun-link-firearm">
                  Consider all the possible manipulations a defender may need to
                  perform in a justified self defense scenario. The individual
                  will or may need to:
                </p>

                <p className="gun-link-firearm">
                  <ul className="dote">
                    <li>
                      Engage and disable all of the passive safety mechanisms
                    </li>
                    <li>Manipulate a manual safety</li>
                    <li>Clear a malfunction</li>
                    <li>Reload the firearm</li>
                  </ul>
                </p>
                <p className="gun-link-firearm">
                  Depending on the person’s hand morphology some of those tasks
                  might be relatively easy to do with one handgun but difficult
                  to do with another. Another person with different hand
                  morphology might find it more difficult to perform the same
                  tasks on the first handgun and easier to do with the other.
                  Even with similar hand morphology, different handedness can
                  play a role in evaluating a handgun’s ease of use. Other
                  things that may need to be considered include, but are not
                  limited to, an individual’s hand and grip strength and their
                  sensitivity to recoil.
                </p>
                <p className="gun-link-firearm">
                  So there you have it. A defensive handgun needs to be
                  reliable, effective, portable, and easy to use. Based on what
                  I’ve seen used by defensive pistol practitioners and what I’ve
                  used myself, here are some of the makes and models I would
                  consider today:
                </p>
                <p className="gun-link-firearm">
                  <ul className="dote">
                    <li>
                      CZ:{' '}
                      <a href="https://www.guns.com/search?keyword=P-10&product.category=HANDGUNS&product.manufacturer=CZ">
                        P-10
                      </a>{' '}
                      series pistols
                    </li>
                    <li>
                      <a href="https://www.guns.com/firearms/handguns/semi-auto?product.manufacturer=GLOCK">
                        Glock
                      </a>{' '}
                      – Pretty much any pistol with the exception of the G44
                      which is chambered for .22 LR
                    </li>
                    <li>
                      Heckler & Koch:{' '}
                      <a href="https://www.guns.com/search?keyword=P30&product.category=HANDGUNS">
                        P30, VP9,
                      </a>{' '}
                      or{' '}
                      <a href="https://www.guns.com/search?keyword=VP40&product.category=HANDGUNS">
                        VP40
                      </a>{' '}
                      series pistols
                    </li>
                    <li>
                      Sig Sauer:{' '}
                      <a href="https://www.guns.com/search?keyword=P320&product.category=HANDGUNS">
                        P320
                      </a>{' '}
                      or{' '}
                      <a href="https://www.guns.com/search?keyword=P365&product.category=HANDGUNS&product.manufacturer=SIG%20SAUER">
                        P365
                      </a>{' '}
                      series pistols
                    </li>
                    <li>
                      Smith & Wesson:{' '}
                      <a href="https://www.guns.com/search?product.category=HANDGUNS&product.manufacturer=SMITH%20&product.specs.Action=STRIKER%20FIRED">
                        M&P 2.0
                      </a>{' '}
                      series pistols
                    </li>
                    <li>
                      Staccato:{' '}
                      <a href="https://www.guns.com/search?keyword=staccato%20c2&product.category=HANDGUNS&product.manufacturer=STACCATO">
                        C2
                      </a>{' '}
                      or{' '}
                      <a href="https://www.guns.com/search?keyword=staccato%20P&product.category=HANDGUNS&product.manufacturer=STACCATO">
                        P
                      </a>{' '}
                      series pistols
                    </li>
                    <li>
                      Walther:{' '}
                      <a href="https://www.guns.com/search?keyword=PDP&product.category=HANDGUNS&product.manufacturer=WALTHER">
                        PDP
                      </a>{' '}
                      series pistols
                    </li>
                  </ul>
                </p>
                <p className="gun-link-firearm">
                  There are several other good options in the market, but these
                  are the ones I see often or have direct experience with. I
                  hope this helps those of y’all in search for a new defensive
                  handgun in one way or another.
                </p>
                <p className="gun-link-firearm">
                  Also remember to look into getting a quality self defense
                  legal plan from Right To Bear Legal Defense.{' '}
                  <a href="/">www.protectwithbear.com</a>
                </p>

                <div className="blog-back">
                  <a href="/righttoblog">
                    <i className="fa fa-long-arrow-left"></i>
                    Back to blog
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainLayout>
    </>
  );
};

export default QualitiesDefensiveHandgun;
