import React from 'react';
import PropTypes from 'prop-types';

const CustomerService = (props) => {
  return (
    <>
      <div className="first-card-price">
        <h3 className="call">{props.Title}</h3>
        <div className="service">
          <h3>{props.Customer}</h3>
          <p>
            <strong>{props.Number}</strong>
          </p>
        </div>
        <div className="service">
          <h3>{props.EmergencyTitle}</h3>
          <p>
            <strong>{props.EmergencyNumber}</strong>
          </p>
        </div>
      </div>
    </>
  );
};

CustomerService.propTypes = {
  Title: PropTypes.string,
  Customer: PropTypes.string,
  Number: PropTypes.string,
  EmergencyTitle: PropTypes.string,
  EmergencyNumber: PropTypes.string,
};

export default CustomerService;
