import React from 'react';
import MainLayout from '../Layout/MainLayout';

const CompanyVision = () => {
  return (
    <>
      <MainLayout>
        <div className="about-banner speak">
          <div className="about-outer">
            <h1 className="banner-heading">
              SPEAK FREELY | LIVE FULLY | DEFEND LIBERTY
            </h1>
          </div>
        </div>
        <div className="american">
          <div className="container">
            <div className="row">
              <div className="american-title">
                <h3>RIGHT TO BEAR MISSION STATEMENT</h3>
                <h4>
                  Right To Bear, is an American self-defense association that is
                  dedicated to the preservation of individual liberty, for all
                  freedom loving people, in the United States, who desire to
                  defend their family with the legal protection necessary, in an
                  era of elites trying to control their lives.
                </h4>
              </div>
            </div>
          </div>
        </div>
      </MainLayout>
    </>
  );
};

export default CompanyVision;
