import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import MainLayout from '../Layout/MainLayout';
import { setLocalData } from '../utils/Storage';

function Referral() {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get('code');

  useEffect(() => {
    if (code !== null) {
      setLocalData('referralCode', code);
      navigate('/joinform');
    } else {
      navigate('/');
    }
  }, [code]);

  return (
    <MainLayout>
      <div className="hero">
        <div className="container">
          <center>Referral Code : {code}</center>
        </div>
      </div>
    </MainLayout>
  );
}

export default Referral;
