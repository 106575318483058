import React from 'react';
import MainLayout from '../Layout/MainLayout';
import { Link } from 'react-router-dom';

const GovernmentFirearm = () => {
  return (
    <>
      <MainLayout>
        <div className="blog-card">
          <div className="container">
            <div className="row">
              <h3 className="blog-bear-heading">Right To Bear Blog</h3>
              <a href="/right-to-bear-personal" className="first-blag-outer">
                <div className="card">
                  <div className="blog-image">
                    <img
                      src="../../images/blog-1.webp"
                      alt="blog-1"
                      className="blog-gun-user"
                    />
                  </div>
                  <div className="card-body">
                    <h5 className="card-title">
                      So You Bought a Handgun for Personal Protection
                    </h5>
                    <p className="card-text">MAY 5 2023</p>
                    <p className="card-sub-title">
                      Owning a handgun for personal protection doesn’t mean one
                      is adequately prepared to use it for that purpose. Just
                      like owning a car doesn’t mean one is ready to start...
                    </p>
                  </div>
                </div>
              </a>
              <ul className="page-linking">
                <li>
                  <Link to="/righttoblog">
                    <i className="fa fa-angle-left"></i>
                  </Link>
                </li>
                <li>
                  <Link to="/righttoblog">1</Link>
                </li>
                <li className="active">
                  <Link to="/government-firearm">2</Link>
                </li>
                {/* <li>
                  <Link to="#">
                    <i className="fa fa-angle-right"></i>
                  </Link>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </MainLayout>
    </>
  );
};

export default GovernmentFirearm;
