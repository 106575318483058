import React from 'react';
import MainLayout from '../Layout/MainLayout';

const Structured = () => {
  return (
    <>
      <MainLayout>
        <div className="personal-gun">
          <div className="container">
            <div className="row">
              <div className="insurance-gun-user">
                <img src="../../images/gun-blog.webp" alt="" />
              </div>
              <div className="handgun">
                <h3 className="handgun-title">
                  A Structured Approach to Maximizing Draw Efficiency and
                  Effectiveness
                </h3>
                <p className="handgun-sub-title">FEBRUARY 27, 2023</p>
              </div>
              <div className="protection">
                <a href="#" className="share-link">
                  <img src="../../images/share.svg" alt="" />
                  Share
                </a>
                <p className="owning-text">
                  Practice doesn’t make perfect. Correct practice makes perfect.
                  The problem is diagnosing and correcting problems in practice
                  can be challenging, but certain practice methods can minimize
                  the amount of diagnosis needed.
                </p>
                <p className="gun-link-firearm">
                  Safely drawing a pistol efficiently and effectively is an
                  important skill to develop for both defensive and competitive
                  applications. In both of those contexts, speed is of the
                  essence but it must be balanced with consistency and ensure a
                  quality in order to establish the best foundation possible to
                  support quick delivery of accurate hits. This isn’t the first
                  time I’ve written on the topic and it probably won’t be the
                  last, but this time around I want to share the current
                  approach I am using to improve my draw with more detail that I
                  have previously as it has evolved into a more structured
                  approach that incorporates things I’ve learned since I last
                  wrote about it.
                </p>
                <p className="gun-link-firearm">
                  Having a holistic approach to practice is important too. While
                  it’s more fun to practice the “running and gunning” it’s
                  important to also consider what happens if you’re ever forced
                  to protect yourself or a loved one. Are you prepared for the
                  legal battle, both criminal and civil, that lies ahead? Do you
                  have the financial resources to hire the best self defense
                  attorney, or will you be stuck with a Public Defender? Before
                  you plan to carry, you need a plan to help you out in the
                  aftermath of a self defense situation. Right To Bear Legal
                  Defense (www.protectwithbear.com) is the most value centered,
                  cost effective, self defense legal protection in America. For
                  less than 50 cents a day, you can have a seasoned, self
                  defense attorney, on speed dial, 24 hours a day, 7 days a
                  week, if you’re ever involved in a self defense use of force
                  situation. Unlike some other companies, Right To Bear has
                  UNLIMITED coverage for one low price and you’ll never have to
                  pay back any of your legal fees. Check out Right To Bear’s
                  coverage at{' '}
                  <a href="/" className="text-change">
                    www.protectwithbear.com
                  </a>{' '}
                  today.
                </p>
                <p className="gun-link-firearm">
                  <ul>
                    <li>
                      Let’s back up just a little. Over the past couple of
                      years, I’ve received a fair amount of formal
                    </li>
                    <li>
                      instruction and taken to heart several notable phrases and
                      expressions used by the
                    </li>
                    <li>
                      instructors while incorporating drills and techniques into
                      my practice sessions.
                    </li>
                  </ul>
                </p>

                <p className="gun-link-firearm">
                  For example, I often make use of Gabe White’s on-demand and
                  pushing practice modalities frequently. This is apparent in
                  several of the drill posts I’ve published where I often reduce
                  par times until “the wheels fall off” and then back things
                  off. In practice when I experience “the wheels falling off”, I
                  can often hear Tom Givens saying, “you can’t miss fast enough
                  to win”, as I start to recall his instruction. As I let off
                  the gas pedal, I then recall Ben Stoeger telling the class,
                  “don’t slow down, fix it.” Which begs the question that I ask
                  myself after finishing the drill, “how do I fix it?” In order
                  to fix it, I have to diagnose the problem which isn’t easy.
                  There are several things happening parallel very quickly when
                  engaged in pushing practice. Whether or not I diagnose the
                  problem correctly, I do often conclude that the problem is
                  often the result of tension that worked its way into the
                  execution of the drill which ultimately led to the mistake (or
                  combination of multiple mistakes) that resulted in the “wheels
                  falling off”. The concept of tension being the culprit that
                  leads to errors is another concept that I picked up from Ben
                  Stoeger.
                </p>
                <p className="gun-link-firearm">
                  I’ve learned several approaches to eliminate or correct
                  technique errors. I won’t name and describe those approaches
                  for the sake of brevity. The common element those approaches
                  have in common is that they all work towards the same goal –
                  allowing one to execute the mechanical skill correctly at
                  speed. The vast majority of those approaches have the same
                  limitation which is they rely on correctly identifying the
                  problem since most approaches are remedies to a specific
                  issue. This isn’t a bad thing in of itself, but it can be a
                  challenge depending on the practitioner’s ability to self
                  diagnose.
                </p>
                <p className="gun-link-firearm">
                  All of this preamble brings us to the structured approach I’m
                  currently using, which is heavily influenced by a video John
                  Correia posted on YouTube over four years ago to which
                  attributes the approach to a video posted by Travis Haley. In
                  all honesty, credit for the core of the approach I’m using
                  goes to them. I’ve only added a few things around it and will
                  share how I have internalized it. I suggest viewing the video
                  in full right now and resume reading the rest of this post.
                </p>
                <p className="gun-link-firearm">
                  <iframe
                    width="728"
                    height="409"
                    src="https://www.youtube.com/embed/E_6LoaU04go"
                    title="Building a Fast Draw to First Shot (Mantis Monday…on Tuesday!) | Active Self Protection Extra"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                </p>

                <p className="gun-link-firearm">
                  The structure approach I am using begins using the time
                  reduction approach described in John Correia’s video with the
                  goal of not beating the time limit, but rather matching the
                  time limit. Another way to think about this is that we are
                  adapting to the draw to specific temp or cadance which begins
                  slow and then picks up the pace. After doing this, I’ll mix
                  and match a few additional drills free from the cadance to
                  measure what we can do. Some days, I also begin with a drill
                  or two that will be added at the end to have a baseline for
                  comparison.
                </p>
                <p className="gun-link-firearm">
                  This activity can be performed with dry or with live
                  ammunition. Whichever method is employed, don’t neglect to
                  adhere to cardinal rules of safe gun handling. Right To Bear
                  Legal Defense also covers accidental discharges, so it’s worth
                  having a plan for you and your family today.{' '}
                  <a href="/" className="text-change">
                    (www.protectwithbear.com)
                  </a>
                </p>
                <p className="gun-link-firearm">Let’s break it down.</p>
                <p className="gun-link-firearm">
                  The 5-second draw is where we begin. Five seconds is a lot of
                  time. The idea here is not to just simply be deliberate, but
                  to allow enough time to be aware of everything happening in
                  the desired sequence. Is the concealment garment being
                  defeated without any unnecessary motion? Are you sure? What
                  are the elbows and shoulders doing and is any of that movement
                  necessary? Was a master grip properly established prior to
                  pulling the pistol out of the holster? Did we establish a
                  proper pectoral/thumb index? Where is the support hand
                  positioned when that index occurred? Where was the support
                  hand grip established and was full grip pressure applied at
                  the right time? Where along the presentation are we beginning
                  to stage the trigger? Did we pick up the front sight or dot at
                  the right place? Was the muzzle and target alignment disturbed
                  as the trigger was pressed all the way to the rear? There is a
                  lot to be aware of and this is the time to do it. The idea is
                  to establish and repeat the most correct draw we are capable
                  of while using the entire time allotted in a fluid motion.
                  Avoid finishing early and waiting at the end to break. Nothing
                  in the motion should be rushed. If one needs more time, then
                  increase the time allowed. Repeat as needed until the draw is
                  consistent and matches the tempo regularly.
                </p>

                <p className="gun-link-firearm">
                  Once we are happy with the 5-second draw, it is time to start
                  increasing the tempo. I like to reduce the time one full
                  second at a time until I get down to two seconds and end with
                  a one and a half second window (this is where the wheels start
                  falling off for me today). Other folks might want to reduce
                  the time in half second steps while others may combine the two
                  or use smaller reduction increments. While I find using
                  increments other than full or half second difficult to time,
                  there is no right or wrong step size here. The goal is to
                  compress the time gradually until one can no longer perform
                  the draw correctly. Or as Gabe White would say, until we find
                  ourselves in a pushing modality.
                </p>
                <p className="gun-link-firearm">
                  As the tempo increases gradually, one will notice a few
                  things. First one will notice the draw will feel familiar and
                  even though the tempo has increased it should be relatively
                  easy to remain relaxed. We may not be able to be aware of
                  everything that is happening with the same level of detail,
                  but we should find that it is not necessary because we just
                  know what to do. This is myelination at work.
                </p>
                <p className="gun-link-firearm">
                  At some point the tempo will be too fast for us to keep up,
                  this may be due to physical limitations or not having
                  developed the skill necessary to draw the pistol and press off
                  a shot at that pace. In other words, we are pushing beyond the
                  limit of our abilities and this is a good place to stop in
                  order to avoid developing bad habits such as rushing out of a
                  holster without a good grip, failing to establish a good
                  support hand grip, or pressing the trigger without a
                  sufficiently refined sight picture for the difficulty of the
                  shot. If one is doing this live ammunition, the shots outside
                  of the target area are a good indication of this. For dry
                  fire, I like to use the shot score from the{' '}
                  <a href="https://unclezo.com/2022/04/21/the-mantis-x10-elite-shooting-performance-system-as-a-dry-fire-aid/">
                    MantisX
                  </a>{' '}
                  as scores below 85 are an indicator to me that the “wheels are
                  starting to fall off”.
                </p>
                <p className="gun-link-firearm">
                  After reaching my limit with the tempo based drills, I like to
                  finish with one or more timed “draw to first shot” drills. For
                  these drills, I am specifically looking to beat the par time
                  by as much as I can, but without rushing and without letting
                  tension creep in. I also like to mix up the difficulty of the
                  target. When performing this dry, I like to work the “Hostage
                  Rescue” drills on the{' '}
                  <a href="https://unclezo.com/2022/04/21/the-mantis-x10-elite-shooting-performance-system-as-a-dry-fire-aid/">
                    MantisX
                  </a>{' '}
                  which is looking for a shot score of 85 or better after coming
                  out of the holster with a time limit. When using live
                  ammunition, getting 10-ring hits on a B-8 at a distance of 7
                  yards works well for this. Use a time limit that is suitable
                  to your skill level. Here are some suggestions (which I’ve
                  taken directly from the time limits of the Hostage Rescue
                  drill available on the{' '}
                  <a href="https://unclezo.com/2022/04/21/the-mantis-x10-elite-shooting-performance-system-as-a-dry-fire-aid/">
                    MantisX
                  </a>
                  application):
                </p>

                <p className="gun-link-firearm-1">
                  The time limits can be adjusted for different target
                  difficulties. It’s a good idea to change it up
                </p>
                <p className="gun-link-firearm">
                  As I mentioned, sometimes I like running a drill before
                  getting into the tempo based work to serve as a baseline. When
                  I’ve done this, I find that the end drill almost always ends
                  up with better results than the results of the cold drill done
                  at the beginning of the practice session. This is expected as
                  the practice that is done warms us up for the closing drill
                  and gets everything dialed in for it. However, I find that
                  being able to see the improvement provides a sense of
                  accomplishment that can help motivate folks to keep on
                  practicing day after day. This is especially true when the
                  improvement is notable. For example, starting off with ten
                  repetitions of the described drill with five successful
                  completions and then ending with ten out of ten attempts at
                  the end of the session feels pretty darn good.
                </p>
                <p className="gun-link-firearm">
                  Logging the scores of the initial and final drills of the
                  session allows us to see the improvement over time. Seeing
                  that one is now averaging seven out of ten repetitions of the
                  cold drill compared to average of five out of ten repetitions
                  the week prior helps to keep motivational levels high and
                  helps develop objective confidence in one’s proficiency with
                  this particular skill.
                </p>
                <p className="gun-link-firearm">
                  I’ve had really good results with this approach in a short
                  period of time and now I’m curious if I can adapt to other
                  skills such as reloads or transitions. I suppose I will have
                  to give it a try. When I do, I will share the details assuming
                  I see positive results.
                </p>
                <p className="gun-link-firearm">
                  I hope those of you who give it a try find positive results
                  from it as well.
                </p>
                <p className="gun-link-firearm">
                  Originally posted at
                  https://unclezo.com/2023/01/19/a-structured-approach-to-maximizing-draw-efficiency-and-effectiveness/
                </p>

                <div className="blog-back">
                  <a href="/righttoblog">
                    <i className="fa fa-long-arrow-left"></i>
                    Back to blog
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainLayout>
    </>
  );
};

export default Structured;
