import React from 'react';
import PropTypes from 'prop-types';

const BlogCard = (props) => {
  return (
    <>
      <a href={props.href} className="outer-card">
        <div className="card">
          <div className="blog-image">
            <img src={props.BlogImage} className="img-fluid" alt="blog-1" />
          </div>
          <div className="card-body ">
            <h5 className="card-title">{props.BlogHeading}</h5>
            <p className="card-text">{props.BlogTime}</p>
            <p className="card-sub-title">{props.BlogTitle}</p>
          </div>
        </div>
      </a>
    </>
  );
};

BlogCard.propTypes = {
  BlogHeading: PropTypes.string,
  BlogTitle: PropTypes.string,
  BlogTime: PropTypes.string,
  BlogImage: PropTypes.string,
  href: PropTypes.string,
};

export default BlogCard;
