import React from 'react';
import MainLayout from '../Layout/MainLayout';

const Mandated = () => {
  return (
    <>
      <MainLayout>
        <div className="personal-gun">
          <div className="container">
            <div className="row">
              <div className="insurance-gun-user">
                <img src="../../images/custom-mahal.webp" alt="" />
              </div>
              <div className="handgun">
                <h3 className="handgun-title">
                  Government Mandated Firearm Insurance
                </h3>
                <p className="handgun-sub-title">FEBRUARY 7, 2023</p>
              </div>
              <div className="protection">
                <a href="#" className="share-link">
                  <img src="../../images/share.svg" alt="" />
                  Share
                </a>
                {/* <p className="owning-text">
                                    Practice doesn’t make perfect. Correct practice makes perfect. The problem is diagnosing and correcting problems in practice can be challenging, but certain practice methods can minimize the amount of diagnosis needed.
                                </p> */}
                <p className="gun-link-firearm">
                  The city of San Jose, California, has a new city ordinance
                  which requires gun owners who reside within the city limits to
                  carry liability insurance and pay an annual fee. In my humble
                  opinion, this ordinance is certifiable grade “A” hogwash and
                  I’ll explain why.
                </p>
                <p className="gun-link-firearm">
                  I don’t normally keep up with the plethora of gun regulations
                  in the state of California for several reasons. First of all,
                  I don’t reside in California and rarely travel there.
                  Additionally, it seems like there is a new gun regulation
                  every other week. On top of all the state regulations, there
                  are also local ordinances to contend with. It’s an awful lot
                  to keep up with.
                </p>
                <p className="gun-link-firearm">
                  Unsurprisingly, I’m late to the game in terms of this
                  ordinance which was first introduced in 2019. From what I’ve
                  gathered, the introduction of the ordinance was,
                  understandably, controversial on the basis of its
                  constitutionality, enforceability, and projected impact on gun
                  violence reduction.
                </p>

                <p className="gun-link-firearm">
                  Now I’m no legal expert, but it doesn’t take much critical
                  thinking to realize that enforcement is only possible by
                  having a list of gun owners and having a mechanism or process
                  in place to verify those gun owners have an active insurance
                  policy. Speaking of which, you know what else requires an
                  annual fee and an active insurance policy? Vehicle
                  registrations. There isn’t even a small logic leap needed to
                  see the similarity. What does require Olympic level mental
                  gymnastics is trying to figure out how any of this will reduce
                  gun violence. Especially, when the city leadership
                  acknowledges the ordinance will not affect residents who
                  unlawfully own guns and commit crimes.
                </p>
                <p className="gun-link-firearm">
                  So what does the ordinance mandate? As I briefly mentioned, it
                  requires gun owners to purchase and maintain an active firearm
                  liability insurance policy and pay an annual fee. Let’s
                  explore those.
                </p>
                <p className="gun-link-firearm">
                  The annual fee, which is placatively dubbed as “Annual Gun
                  Harm Reduction Fee”, is a required fee imposed on any person
                  who resides in San Jose and owns or possesses a firearm. The
                  amount of the fee payment schedule isn’t defined or
                  constrained. Rather, the payment schedule is left to the city
                  manager to decide and the amount is left up to the city
                  council to determine. This is a huge problem for gun owners
                  because the fee could be a modest small amount or an obscenely
                  huge tariff. Not to mention that the amount is subject to
                  change and the rate of change isn’t capped.
                </p>
                <p className="gun-link-firearm">
                  The ordinance does impose some guidelines as to how the city
                  can use that money. It specifically requires that all the
                  money collected will be used by a designated nonprofit
                  organization to provide services to firearm owning residents
                  and members of their household. A few services like, suicide
                  prevention programs, violence reduction programs, domestic
                  violence services, gun violence related mental health
                  services, and firearms safety education or training. However,
                  the list isn’t exclusionary. Furthermore, the designation of
                  the nonprofit organization is left up to the city council once
                  again. Call me skeptical, but I wouldn’t be surprised to learn
                  the funds end up going to a nonprofit gun control advocacy
                  group for said services.
                </p>

                <p className="gun-link-firearm">
                  Regardless of what the fee is called or how the funds are
                  used, I can’t help but see it as a tax. A tax that raises the
                  cost of gun ownership and therefore makes legal gun ownership
                  less accessible to lower income households who are more likely
                  to find themselves in need of one for defensive purposes.
                </p>
                <p className="gun-link-firearm">
                  In terms of the liability requirement, the ordinance calls for
                  a policy that specifically covers losses or damages resulting
                  from negligent and accidental discharges. The amount of
                  coverage required is not specified and it appears that the
                  coverage amount may be specified in regulations left up to the
                  city manager to define.
                </p>
                <p className="gun-link-firearm">
                  I do think that having a firearms liability protection is a
                  good idea. In fact, I personally carry a
                </p>
                <p className="gun-link-firearm">
                  Right To Bear membership myself which happens to offer
                  accidental discharge coverage. However, I’m not a fan of being
                  forced to carry such a policy. The requirement is a forced
                  expense which further raises the total cost of ownership and
                  yet again makes legal gun ownership less accessible to folks
                  with financial constraints.
                </p>

                <p className="gun-link-firearm">
                  I’m certain somebody somewhere will point out that the
                  ordinance does list an exception for financial hardship making
                  my complaint about making legal gun ownership less accessible
                  to lower income household’s moot. While there is, in fact, a
                  financial exception listed, the criteria by which a person can
                  claim the exemption is, predictably, left up to the city
                  manager to define. Additionally, peace officers and folks
                  licensed to carry a concealed weapon are also exempted from
                  the annual fee and insurance requirements.
                </p>
                <p className="gun-link-firearm">
                  Coming back full circle to the similarities between this
                  ordinance and vehicle registration, the ordinance requires
                  firearm owners to produce proof of insurance and annual fee
                  payment to peace officers who request it. Failure to produce
                  the documentation or failure to comply with the ordinance can
                  result in an administrative citation, fines, or impoundment of
                  the owned firearms.
                </p>
                <p className="gun-link-firearm">
                  So, what can a gun owner who resides in San Jose, California
                  do?
                </p>
                <p className="gun-link-firearm">
                  My first suggestion is to move to a location that is gun
                  friendly. However, I understand this may not be possible or
                  practical for many reasons. s
                </p>

                <p className="gun-link-firearm-1">
                  The next suggestion is to get involved with local, regional,
                  and national gun rights organizations. More specifically, get
                  involved with those who are fighting the legal battle to
                  strike down the ordinance. From what I’ve gathered, the gun
                  rights organizations that are actively involved in the legal
                  battles are: National Association for Gun Rights and Gun
                  Owners of California. There are many ways to get involved -
                  some ways require time, other ways require money.
                </p>
                <p className="gun-link-firearm">
                  Last but not least, it’s not a bad idea to get an armed
                  self-defense plan like the memberships offered by Right to
                  Bear. Feel free to check out the review I wrote about their
                  plan and policy after I personally purchased one over here.
                </p>
                <p className="gun-link-firearm">
                  Author: Uncle Zo - https://unclezo.com/
                </p>

                <div className="blog-back">
                  <a href="/righttoblog">
                    <i className="fa fa-long-arrow-left"></i>
                    Back to blog
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainLayout>
    </>
  );
};

export default Mandated;
