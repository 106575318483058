import React from 'react';
import MainLayout from '../Layout/MainLayout';
import PackageForm from '../components/PackageForm';
const AffiliateNetWork = () => {
  return (
    <>
      <MainLayout>
        <div className="about-banner affiliate-banner">
          <div className="about-outer">
            <h1 className="banner-heading">AFFILIATE PROGRAM</h1>
          </div>
        </div>
        <div className="details-net">
          <div className="container">
            <div className="row">
              <div className="details-net-text">
                <h3>Program Details.</h3>
                <p>
                  Our Affiliate Program makes it easy for like-minded business
                  owners and influencers to turn our shared passion for
                  protecting responsible firearms owners in defense of their 2nd
                  Amendment rights, into revenues on qualifying membership sales
                  as a &apos;marketing partner&apos; with Right To Bear
                  Association. Our easy process will link your website, eblast,
                  or app databases (via Avantlink) to our self-defense
                  association and its benefits. Providing the following
                  compensation to YOU for each validated association membership
                  sold to one of your current customers/followers:
                </p>
              </div>
            </div>
          </div>
        </div>
        <PackageForm />
        <div className="line-image">
          <div className="container">
            <div className="row">
              <img src="../../images/line.svg" alt="" />
            </div>
          </div>
        </div>
        <div className="qualifying">
          <div className="container">
            <div className="row">
              <h3 className="quint-heading">
                AFFILIATES CAN BE (QUALIFYING AFFILIATE SITES)
              </h3>
              <div className="sites">
                <img src="../../images/shield_svg.svg" alt="" />
                <p>Publishers</p>
              </div>
              <div className="sites">
                <img src="../../images/shield_svg.svg" alt="" />
                <p>Gun Dealer Sites</p>
              </div>
              <div className="sites">
                <img src="../../images/shield_svg.svg" alt="" />
                <p>Gun enthusiast</p>
              </div>
              <div className="sites">
                <img src="../../images/shield_svg.svg" alt="" />
                <p>Product Review Sites</p>
              </div>
              <div className="sites">
                <img src="../../images/shield_svg.svg" alt="" />
                <p>Social Media Sites</p>
              </div>
              <div className="sites">
                <img src="../../images/shield_svg.svg" alt="" />
                <p>And more… contact us if you aren’t sure if you qualify</p>
              </div>
              <h3 className="quint-heading quint-2">WHAT ARE THE PERKS?</h3>
              <div className="sites">
                <img src="../../images/shield_svg.svg" alt="" />
                <p>
                  You’ll have access to a Program Manager to work directly with
                  you and your account.
                </p>
              </div>
              <div className="sites">
                <img src="../../images/shield_svg.svg" alt="" />
                <p>
                  Our best-in-class self-defense program offers the most value
                  centered self-defense protection and associate benefits in the
                  country.
                </p>
              </div>
              <div className="sites">
                <img src="../../images/shield_svg.svg" alt="" />
                <p>Offering the protection your customers NEED.</p>
              </div>
              <div className="sites">
                <img src="../../images/shield_svg.svg" alt="" />
                <p>No Risk. High Reward.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="line-image">
          <div className="container">
            <div className="row">
              <img src="../../images/line.svg" alt="" />
            </div>
          </div>
        </div>
        <div className="qualifying mb-4">
          <div className="container">
            <div className="row">
              <h3 className="quint-heading">QUESTIONS ABOUT OUR PROGRAM?</h3>
              <div className="sites">
                <img src="../../images/shield_svg.svg" alt="" />
                <p>perry.orth@moultrieinsurance.com</p>
              </div>
              <div className="sites">
                <img src="../../images/shield_svg.svg" alt="" />
                <p>affiliate@protectwithbear.com</p>
              </div>
            </div>
          </div>
        </div>
      </MainLayout>
    </>
  );
};

export default AffiliateNetWork;
