import { createSlice } from '@reduxjs/toolkit';
import { getApi } from '../../../utils/api';
import { packages } from '../../../utils/endpoint';

const initialState = {
  packages: [],
};

const packageSlice = createSlice({
  name: 'package',
  initialState,
  reducers: {
    setData: (state, action) => {
      return {
        ...state,
        packages: action.payload,
      };
    },
  },
});

export const { setData } = packageSlice.actions;

export const fetchPackage = () => async (dispatch) => {
  try {
    getApi(packages).then((res) => {
      dispatch(setData(res.data.data));
    });
  } catch (error) {
    console.log(error);
  }
};

export default packageSlice.reducer;
