import React from 'react';
import MainLayout from '../Layout/MainLayout';

const InstructorPortal = () => {
  return (
    <>
      <MainLayout>
        <div className="about-banner instructor">
          <div className="about-outer">
            <h1 className="banner-heading">INSTRUCTOR PORTAL</h1>
          </div>
        </div>

        <div className="coming-soon">
          <h2>Coming soon...</h2>
        </div>
      </MainLayout>
    </>
  );
};

export default InstructorPortal;
