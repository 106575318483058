import React, { useState } from 'react';
import AccountMainLayout from '../Layout/AccountMainLayout';
import { Link } from 'react-router-dom';

const MinorHouseholdChildrenYearly = () => {
  const [count, setCount] = useState(1);
  return (
    <AccountMainLayout>
      <div className="account-login px-0">
        <div className="container">
          <div className="row">
            <div className="children-yearly">
              <div className="children">
                <p className="plan-quantity">RIGHT TO BEAR</p>
                <h1>Minor Household Children Yearly</h1>
                <h5>$35.00 USD</h5>
                <p className="plan-quantity">Quantity</p>
                <div className="plan-plus-minus">
                  <button
                    onClick={() => (count > 0 ? setCount(count - 1) : null)}
                  >
                    {/* <i class="fa fa-minus" aria-hidden="true"></i> */}
                    <img src="../images/collapse.svg" alt="" />
                  </button>
                  <button>{count}</button>
                  <button onClick={() => setCount(count + 1)}>
                    <img src="../images/plus2.svg" alt="" />
                  </button>
                </div>
                <div className="add-to-cart">
                  <button className="button-black button-black-size">
                    ADD TO CART
                  </button>
                </div>
              </div>
              <div className="accordion-materials">
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwo">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        <img
                          src="../images/material.svg"
                          alt="material"
                          className="me-3"
                        />
                        Materials
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p>heelo</p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThree">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        <img
                          src="../images/shipping.svg"
                          alt="shipping"
                          className="me-3"
                        />
                        Shipping & Returns
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p></p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFour">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFour"
                        aria-expanded="false"
                        aria-controls="collapseFour"
                      >
                        <img
                          src="../images/Dimension.svg"
                          alt="Dimension"
                          className="me-3"
                        />
                        Shipping & Returns
                      </button>
                    </h2>
                    <div
                      id="collapseFour"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingFour"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p></p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFive">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFive"
                        aria-expanded="false"
                        aria-controls="collapseFive"
                      >
                        <img
                          src="../images/care.svg"
                          alt="Dimension"
                          className="me-3"
                        />
                        Shipping & Returns
                      </button>
                    </h2>
                    <div
                      id="collapseFive"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingFive"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="share-button">
                <img src="../images/share.svg" alt="share" />
                <span>Share</span>
              </div>
            </div>
          </div>
        </div>
        <div className="collection">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-6">
                <div className="image-text">
                  <h3 className="sub-title">IMAGE WITH TEXT</h3>
                  <p>
                    Pair text with an image to focus on your chosen product,
                    collection, or blog post. Add details on availability,
                    style, or even provide a review.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 order-lg-last order-first">
                <div className="black-image">
                  <img src="../images/home.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="free-shipping">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-6">
                <div className="free-text">
                  <h4>Free Shipping</h4>
                  <p>
                    Pair text with an image to focus on your chosen product,
                    collection, or blog post. Add details on availability,
                    style, or even provide a review.
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="free-text">
                  <h4>Hassle-Free Exchanges</h4>
                  <p>
                    Pair text with an image to focus on your chosen product,
                    collection, or blog post. Add details on availability,
                    style, or even provide a review.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="also">
          <div className="container-fluid">
            <div className="row">
              <div className="col-6 col-lg-3">
                <div className="card_content">
                  <div className="card_information">
                    <div className="card_heading">
                      <Link to="#">Minor Household Children Yearly</Link>
                    </div>
                  </div>
                  <div className="card_footer">
                    <h4>$30.00 USD</h4>
                  </div>
                </div>
              </div>
              <div className="col-6 col-lg-3">
                <div className="card_content">
                  <div className="card_information">
                    <div className="card_heading">
                      <Link to="#">Minor Household Children Monthly</Link>
                    </div>
                  </div>
                  <div className="card_footer">
                    <h4>$30.00 USD</h4>
                  </div>
                </div>
              </div>
              <div className="col-6 col-lg-3">
                <div className="card_content">
                  <div className="card_information">
                    <div className="card_heading">
                      <Link to="#">Minor Household Children Monthly</Link>
                    </div>
                  </div>
                  <div className="card_footer">
                    <h4>$30.00 USD</h4>
                  </div>
                </div>
              </div>
              <div className="col-6 col-lg-3">
                <div className="card_content">
                  <div className="card_information">
                    <div className="card_heading">
                      <Link to="#">Minor Household Children Yearly</Link>
                    </div>
                  </div>
                  <div className="card_footer">
                    <h4>$30.00 USD</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AccountMainLayout>
  );
};

export default MinorHouseholdChildrenYearly;
