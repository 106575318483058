import React from 'react';
import MainLayout from '../Layout/MainLayout';

const Stabilizing = () => {
  return (
    <>
      <MainLayout>
        <div className="personal-gun">
          <div className="container">
            <div className="row">
              <div className="insurance-gun-user">
                <img src="../../images/car-horan.jpg" alt="" />
              </div>
              <div className="handgun">
                <h3 className="handgun-title">
                  The All New ATF Stabilizing Brace Rule and You
                </h3>
                <p className="handgun-sub-title">FEBRUARY 9, 2023</p>
              </div>
              <div className="protection">
                <a href="#" className="share-link">
                  <img src="../../images/share.svg" alt="" />
                  Share
                </a>
                <p className="owning-text-1">
                  Some folks want more restrictions on firearms. Some gun owners
                  aren’t bothered by restrictions on types of firearms they
                  don’t own. Both groups might want to pay attention to what the
                  ATF is doing to bump stocks… err... I mean stabilizing braces.
                </p>
                <p className="gun-link-firearm">
                  A few weeks ago, the Bureau of Alcohol, Tobacco, Firearms, and
                  Explosives (BATFE or ATF) announced their final rule on
                  stabilizing braces (2021R-08F Factoring Criteria for Firearms
                  with Attached “Stabilizing Braces”) and submitted it for
                  publishing to the Federal Register. Since then many readers
                  have asked for my opinion or interpretation of the rule.
                  Rather than jumping the gun and immediately sharing my
                  thoughts, I decided to take a moment to process the
                  information while setting up a conversation with some folks
                  that know way more about this than I do. In other words, I
                  called my friends at Right To Bear for some help.
                </p>
                <p className="gun-link-firearm">
                  Right To Bear offers self defense protection insurance to
                  private citizens. They have been an active supporter of this
                  blog. To be clear and transparent, I do have a monetary
                  agreement with Right To Bear where I am paid a small
                  commission for sign ups via links available on this blog (or
                  other communication channels) or via the use of the discount
                  code “UNCLEZO” at ProtectWithBear.com which will yield a 10%
                  discount of the first year’s plan fees which they have
                  significantly reduced and simplified since their inception
                  last year.
                </p>
                <p className="gun-link-firearm">
                  Since Right To Bear operates amidst the legal space, it made
                  sense to me that they might know quite a bit more about this
                  regulatory change than I do. The conversation we had was
                  educational and frightening. Before I get into the nuts and
                  bolts, I also need to be very clear that what is written in
                  this post is absolutely not legal advice. I am not an attorney
                  and neither was Scott, the Right To Bear representative that I
                  spoke with. What I will share with you is strictly my opinion
                  and understanding which may very well be incorrect and subject
                  to change as the situation evolves and more information
                  becomes available.
                </p>

                <p className="gun-link-firearm">
                  All right. Let’s get into it.
                </p>
                <p className="gun-link-firearm">
                  The new rule which was officially published in the Federal
                  Register on January 31, 2023 has effectively reclassified
                  certain firearms with attached stabilizing braces as
                  short-barreled rifles (SBR). This begs several questions.
                  Which firearms? What are stabilizing braces? What are SBRs?
                  Unfortunately, the answers to some of these questions are
                  ambiguous and appear to be the beginning of a rapidly
                  expanding legal quagmire. Nevertheless, that’s what we are
                  here to explore.
                </p>
                <p className="gun-link-firearm">
                  “What is an SBR?”, is arguably the easiest question to answer.
                  A short-barreled rifle is a rifle with a rifled barrel length
                  of less than 16” or an overall length of less than 26” that is
                  designed to be fired from the shoulder. An SBR is also a
                  weapon that is regulated under the National Firearms Act
                  (NFA). That translates into a weapon that cannot be legally
                  owned without special authorization from the ATF which
                  requires submitting fingerprints, paperwork, undergoing a
                  background check, and paying a special tax. Ownership of a
                  regulated NFA item also imposes additional legal requirements
                  in order to travel with it or transfer of ownership. It is
                  entirely possible to own one, in fact many people do, assuming
                  one jumps through all of those hoops and lives in a
                  jurisdiction where it is legal to do so. Possessing one
                  without going through the proper legal channels is a felony
                  that is punishable by 10 years in prison and a $250,000 fine.
                </p>
                <p className="gun-link-firearm">
                  Stabilizing braces are a device that can be attached to the
                  rear of a firearm and is designed to be secured to a forearm
                  which aids in the stabilization of the firearm while operating
                  it. For many years, the ATF has given the proverbial thumbs up
                  to these devices being attached to firearms which are now
                  reclassified as SBRs.
                </p>

                <p className="gun-link-firearm">
                  Things start to get ambiguous when we start talking about
                  which firearms with attached stabilizing braces are impacted
                  by the new rule. The obvious targets of this rule are AR and
                  AK platform pistols. These are firearms that have been
                  designed and manufactured without stocks and feature rifled
                  barrels that are less than 16” long which remain free from NFA
                  regulation so long as they don’t have an attached stabilizing
                  brace. However, other types of pistols may be considered SBRs
                  if their design, weight, or size are similar to another SBR in
                  the market. Another thing that may result in a firearm with an
                  attached brace being classified as an SBR is if marketing
                  material, operating instructions, or community discussion
                  (read that as social media posts) suggest the possibility of
                  operating the firearm from a shoulder. That includes content
                  that is yet to be created. This isn’t only ambiguous, but it
                  is also a moving target as future SBR products and content can
                  perpetually move the goalposts.
                </p>
                <p className="gun-link-firearm">
                  The implications of the precedent this new rule sets is scary
                  as it effectively allows an agency to create or alter laws
                  that carry heavy criminal punishment with. Even worse is that
                  an agency is effectively defining what a private citizen can
                  and can’t own and puts pre-existing owners in a very dangerous
                  predicament they may not even be aware of. Let that sink in
                  for a minute. I realize that the items in question with
                  regards to this rule are “firearms with attached stabilizing
                  braces”, but don’t forget there have been ongoing discussions
                  about banning natural gas appliances and internal combustion
                  engines. This rule could be the precedent that allows other
                  agencies that do not have legislative authority to essentially
                  make laws. This might seem like a big stretch, but several
                  people were labeled as conspiracy theorists when they
                  suggested that the bump stock ban could lead to things like
                  this rule.
                </p>
                <p className="gun-link-firearm">
                  I want to once again emphasize the predicament pre-existing
                  owners of these firearms with stabilizing braces. This is a
                  huge problem. Industry experts estimate there are somewhere
                  between 10 to 40 million owners of these firearms with
                  attached stabilizing braces that are impacted by this rule
                  change. Chances are the majority of these owners aren’t aware
                  that this rule exists and may never be aware of its existence
                  at all. Unfortunately, ignorance of laws and regulations is
                  not a viable defense strategy when facing criminal charges.
                  While I highly doubt those owners will be “rounded up” and
                  tossed in jail since the resources and coordination required
                  for mass enforcement campaigns are astronomical, there are
                  likely millions of owners who now possess an SBR illegally and
                  will be unaware of that fact for years to come. This poses a
                  serious risk because in the possibility exists that during the
                  course of their daily lives and without any malicious intent
                  have an encounter with law enforcement that results in the
                  discovery of unknown illegal possession of an SBR which then
                  leads to criminal charges. This interaction could be the
                  justified use of one of these weapons in a self defense
                  incident, a traffic stop while traveling with the weapon for
                  recreational target shooting, or a response to a medical
                  emergency where the weapon is simply locked again in a display
                  case at home.
                </p>
                <p className="gun-link-firearm">
                  So what can we do? Aside from the five options the ATF has set
                  forth in order to comply with this new rule, there are a few
                  additional courses of action that I would encourage folks to
                  take.
                </p>

                <p className="gun-link-firearm">
                  The first course of action is to sign up for a firearms or
                  self defense legal plan or insurance policy. While I am
                  partial to Right To Bear because they are a sponsor of the
                  blog and believe enough in the value their plan provides to be
                  a customer myself, I will encourage each one of y’all to look
                  into the plans and policies offered by reputable providers to
                  select the one that fits your needs and budget the best. They
                  aren’t all the same. However, the one thing all reputable
                  providers have in common is that they all provide access to an
                  attorney to get legal advice that is specific to your
                  situation at no additional charge. This is important because
                  laws and regulations are changing all the time and can vary
                  from one jurisdiction to another. I use this feature regularly
                  to make sure I’m not putting myself at legal risk by
                  participating in a firearms related activity, practice, or
                  transaction. For example, one can call to discuss the details
                  of an upcoming private party sale of a specific firearm. In
                  this scenario, the attorney may point out that the firearm
                  that is about to be transferred is subject to NFA regulations
                  and could put both parties in grave legal jeopardy.
                  Alternatively, the attorney may suggest paperwork to include
                  in the transfer or inquiries to make of the other party to
                  minimize legal risk. In the context of this rule, it wouldn’t
                  hurt for folks who own one or more of these firearms with
                  stabilizing braces, which are now classified as SBRs, to get
                  some specific advice about what to do with them and how to go
                  about it.
                </p>
                <p className="gun-link-firearm">
                  The second course of action that I will encourage is to
                  contact your government representatives and express your
                  concerns and thoughts about the new rule. I suspect that
                  anyone who has read this far into this post is impacted by
                  this rule and considers it to be a steaming pile of bovine
                  manure like I do. I get that many feel that voicing opinions
                  will change nothing. However, silence will be inferred as
                  acceptance. That will certainly change nothing and may invite
                  truckloads of additional steaming piles of bovine manure.
                </p>
                <p className="gun-link-firearm">
                  One final course of action that I wholeheartedly encourage is
                  to donate to and support your favorite gun rights
                  organization. Law suits aren’t free and they are already being
                  filed seeking injunctions while looking to overturn the rule.
                  If you are unsure of which organization to fund, then I’ll
                  suggest making donations to the Firearms Policy Coalition or
                  Gun Owners of America. The fight I have a dog in is in the
                  fight to preserve and protect our rights which others would
                  like to restrict and take away.
                </p>
                <p className="gun-link-firearm">
                  With that out of the way, let’s take a look at the five
                  options for compliance the ATF has made available to some of
                  us. I say some of us because local jurisdictional restrictions
                  may make some of these options unviable for some of us. Again,
                  go get sound legal advice specific to your situation in your
                  jurisdiction.
                </p>

                <p className="gun-link-firearm">
                  Option 1, replace the short barrel with a barrel that is 16”
                  or longer. Seems simple enough on the surface, but it comes
                  along with some quandaries to consider. If we are talking
                  about an AR platform pistol, this could be done by replacing
                  the upper receiver with one that has a barrel that is 16” or
                  longer. However, leaving the replaced upper receiver that has
                  a shorter than 16” barrel in the vicinity of the firearm,
                  other AR-15s, or other lower receivers could be construed as
                  having “constructive possession” or “constructive intent” of
                  an SBR which carries the same penalty as being in possession
                  of an SBR. Some potential actions one could take to avoid this
                  is to store the replaced upper or barrel in a separate
                  location like another safe or another room. However, whether
                  or not the storage is separated enough to avoid the
                  possibility of it being construed as “constructive possession”
                  or “constructive intent” isn’t well defined. Alternatively,
                  one could get rid of the replaced barrel or upper receiver to
                  eliminate that potential risk. As one might suspect, the cost
                  associated with obtaining the parts, gunsmithing services,
                  additional storage devices, or disposal of the replaced parts
                  rests entirely with the owner.
                </p>
                <p className="gun-link-firearm">
                  Folks who go with option 1 should check to ensure the firearm
                  also meets the overall length of 26” or greater requirement
                  for a rifle. It is unclear if this requirement is measured
                  with a telescoping or adjustable stabilizing brace extended or
                  retracted. Airing on the side of caution and ensuring that
                  requirement is met with brace retracted is probably a good
                  idea.
                </p>
                <p className="gun-link-firearm">
                  Option 2, remove the brace so that it cannot be reattached.
                  This option is about as clear as mud. The only thing that is
                  clear is that removal of the brace is not sufficient. One
                  option here is to modify the brace so that it can not be
                  reattached which means turning the brace into unusable trash
                  since it means it can’t be attached to anything. Another
                  option might be to alter the firearm so that a brace can not
                  be attached to it. This might be achievable by replacing the
                  buffer tube with a tube that has no attachment surface. Like
                  option 1, this could create the “constructive” possession or
                  intent risks if one decides to hold on to the replaced parts.
                  And once again, the burden of cost related to the modification
                  rests entirely on the owner.
                </p>
                <p className="gun-link-firearm">
                  Option 3, forfeit the firearm at a local ATF office. I can’t
                  imagine any scenario where this is a good idea, but it may be
                  the right or only viable option for compliance with this rule
                  to some folks. While I think it’s in poor taste to offer this
                  option without fair market value compensation for the item,
                  I’m not surprised by it given the bump stock ban set the
                  precedent for it.
                </p>
                <p className="gun-link-firearm">
                  Option 4, destroy the firearm. My opinion and interpretation
                  of this option is identical to option 3. Folks who for
                  whatever reason decide to go this route should be aware that
                  there are specific requirements to ensure the firearm is
                  properly destroyed. Failure to meet those requirements, could
                  result in the firearm being considered unserviceable rather
                  than destroyed. Unserviceable firearms are still regulated as
                  plain old firearms under Federal Law. Which means that
                  possessing the unserviceable SBR carries the same criminal
                  penalties as possessing an SBR.
                </p>
                <p className="gun-link-firearm">
                  Option 5, register the SBR via an e-Form 1 during the 120 day
                  compliance period. The compliance period began when the rule
                  was published in the Federal Register on January 31, 2023.
                  Which means that folks have until May 31, 2023, to complete
                  and fire the e-Form 1. During this time period, the $200 tax
                  stamp fee is waived for those who possessed the firearm with
                  the attached stabilizing brace prior to January 31, 2023. This
                  option expands the legal quagmire created by this rule beyond
                  the visible horizon. I’ll do my very best to convey my
                  understanding and perhaps more importantly my lack of
                  understanding as I can see the allure that this option may
                  hold for many.
                </p>
                <p className="gun-link-firearm">
                  The e-Form 1 registration is a lengthy process that requires
                  submission of fingerprints, passport photos, and personal
                  information that is sent to both the ATF and the local head of
                  law enforcement. Depending on one’s jurisdiction, the local
                  head of law enforcement may be part of the approval process
                  which is not guaranteed. This brings up a valid concern. What
                  happens if the registration is denied? The stark reality is
                  that one will be in illegal possession of an SBR. One could be
                  prosecuted for it. Perhaps one will have the option to pursue
                  one of the other options to achieve compliance. Will one be
                  allowed to appeal the denial? Or an opportunity to clear up
                  something in their background that turned up during the
                  background check that resulted in the detail and apply again?
                  If so, will one then be responsible for paying the $200 tax
                  stamp fee? While sometimes in error, denials do happen. Being
                  prepared to deal with a denial is a good idea.
                </p>
                <p className="gun-link-firearm">
                  Assuming one is approved, does one have the understanding of
                  the risks and responsibilities that come with owning and
                  possessing an NFA item? I suspect that this will be the first
                  NFA item for several folks who chose this option. There is
                  some education that needs to happen here. Ideally that
                  education should happen before pulling the trigger. But
                  remember there is a limited window of opportunity for this
                  option and education takes time as does obtaining
                  fingerprints, passport photos, and submitting the e-Form 1.
                </p>
                <p className="gun-link-firearm">
                  An important limitation for the registration option, is that
                  the firearm with the stabilizing brace must have been in
                  possession prior to January 31, 2023. I could be wrong here,
                  but folks who came (or come) into possession of one of these
                  items after that date are most likely in illegal possession of
                  an SBR which may not be registered via this option. Those who
                  fall into this category would best be served by consulting
                  with an attorney to determine what their best course of action
                  may be.
                </p>
                <p className="gun-link-firearm">
                  Another important limitation is that the registration process
                  has a very narrow scope that allows for registration of the
                  SBR to a trust. It is my understanding that evidence that the
                  firearm with the stabilizing brace was owned and possessed by
                  the registering trust prior to January 31, 2023 is necessary.
                </p>
                <p className="gun-link-firearm">
                  If all of this wasn’t messy already, there are still a number
                  of curve balls that can potentially make things even messier.
                  For example, let’s say one decides to register the SBR and
                  starts the process, what happens if a judge who is presiding
                  over one of the already filed lawsuits orders an injunction?
                  Additionally, the ATF has been updating (and consequently
                  removing) FAQs on their websites (including the e-Form)
                  website which has caused a lot of confusion. While the FAQs
                  are not officially published rules and regulations, common
                  folks look to them for guidance in maintaining compliance with
                  the official rules and regulations. Mistakes or changes in
                  those FAQs could lead to actions or practices that land one in
                  hot water. While that might be much easier for a defense
                  attorney to build a good case, it can still be a costly
                  process that isn’t risk free.
                </p>
                <p className="gun-link-firearm">
                  At the risk of sounding like a broken record, obtaining legal
                  advice from a licensed attorney is a good idea regardless of
                  which option is taken. Every single option, even those not
                  mentioned in this post, carry some manner of legal risk. Being
                  aware and understanding that risk is critical. As I’ve said,
                  ignorance, while it may be blissful, is not a viable defense
                  strategy in a court of law.
                </p>
                <p className="gun-link-firearm mb-5">
                  For those of you who don’t have a firearms or self defense
                  legal plan and policy, I will once again encourage you to
                  consider a plan from Right To Bear. It is a great value with
                  an extremely competitive price starting at $11/month or
                  $125/year that includes a $2,000,000 liability policy without
                  a repudiation clause and allows one to pick their own
                  attorney. In the event any of y’all decide this is for you,
                  don’t forget to use the code “UNCLEZO” at ProtectWithBear.com
                  to receive 10% off the first year.
                </p>

                <div className="blog-back" style={{ marginTop: '100px' }}>
                  <a href="/righttoblog">
                    <i className="fa fa-long-arrow-left"></i>
                    Back to blog
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainLayout>
    </>
  );
};

export default Stabilizing;
