import { createSlice } from '@reduxjs/toolkit';
import { postApi } from '../../../utils/api';
import { signup } from '../../../utils/endpoint';
import Cookies from 'js-cookie';

const initialState = {
  personalDetail: {},
  billingDetail: {},
  questionnaireDetail: {},
};

const signupSlice = createSlice({
  name: 'signup',
  initialState,
  reducers: {
    setPersonalDetail: (state, action) => {
      state.personalDetail = action.payload;
    },
    setBillingDetail: (state, action) => {
      state.billingDetail = action.payload;
    },
    setQuestionnaireDetail: (state, action) => {
      state.questionnaireDetail = action.payload;
    },
  },
});

export const { setPersonalDetail, setBillingDetail, setQuestionnaireDetail } =
  signupSlice.actions;

export const signupApi = (data) => async (dispatch) => {
  try {
    const formdata = new FormData();
    formdata.append('name', data.firstName);
    formdata.append('last_name', data.lastName);
    formdata.append('email', data.email);
    formdata.append('telephone', data.phoneNumber);
    formdata.append('password', data.password);
    formdata.append('password_confirmation', data.confirmPassword);
    formdata.append('user_type', data.userType);
    formdata.append('company_name', data.company);
    formdata.append('dob', data.DOB);
    formdata.append('gender', 'Male');
    formdata.append('city', data.city);
    formdata.append('state', data.state);
    formdata.append('zipcode', data.zipCode);
    formdata.append('address', data.address);
    formdata.append('country', 'US');

    postApi(signup, formdata)
      .then((res) => {
        Cookies.set('userToken', res.data.data.token);
        dispatch(setPersonalDetail(data));
      })
      .catch((e) => {
        console.log(e);
      });
  } catch (error) {
    console.log(error);
  }
};

export default signupSlice.reducer;
