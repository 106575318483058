import React from 'react';
import PropTypes from 'prop-types';

const ErrorBanner = ({ error, handleClose }) => {
  return (
    <div
      className={
        error.isError
          ? 'alert_inner error-background'
          : 'alert_inner info-background'
      }
    >
      <span>{error.message}</span>
      <a className="close__alert" onClick={handleClose}>
        ×
      </a>
    </div>
  );
};
ErrorBanner.propTypes = {
  error: PropTypes.shape({
    isError: PropTypes.bool,
    message: PropTypes.string,
  }),
  handleClose: PropTypes.func,
};
export default ErrorBanner;
