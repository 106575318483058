import React from 'react';
import MainLayout from '../Layout/MainLayout';
import BlogCard from '../components/BlogCard';
import { Link } from 'react-router-dom';

const RightToBlog = () => {
  return (
    <>
      <MainLayout>
        <div className="blog-card">
          <div className="container">
            <div className="row">
              <h3 className="blog-bear-heading">Right To Bear Blog</h3>
              <a href="/right-to-bear-personal" className="first-blag-outer">
                <div className="card">
                  <div className="blog-image">
                    <img
                      src="../../images/blog-1.webp"
                      alt="blog-1"
                      className="blog-gun-user"
                    />
                  </div>
                  <div className="card-body">
                    <h5 className="card-title">
                      So You Bought a Handgun for Personal Protection
                    </h5>
                    <p className="card-text">MAY 5 2023</p>
                    <p className="card-sub-title">
                      Owning a handgun for personal protection doesn’t mean one
                      is adequately prepared to use it for that purpose. Just
                      like owning a car doesn’t mean one is ready to start...
                    </p>
                  </div>
                </div>
              </a>
              <div className="col-md-6">
                <BlogCard
                  BlogImage="../../images/blog-2.webp"
                  BlogHeading="To Open Carry or Concealed Carry?"
                  BlogTime="MAY 5 2023"
                  BlogTitle="Owning a handgun for personal protection doesn’t mean one is adequately prepared to use it for that purpose. Just like owning a car doesn’t mean one is ready to start..."
                  href="/open-carry"
                />
              </div>
              <div className="col-md-6">
                <BlogCard
                  BlogImage="../../images/blog-3.webp"
                  BlogHeading="A Structured Approach to Maximizing Draw Effici..."
                  BlogTime="February 27, 2023"
                  BlogTitle="Practice doesn’t make perfect. Correct practice makes perfect. The problem is diagnosing and correcting problems in practice can be challenging, but certain practice methods can minimize the amount of diagnosis...
                                    "
                  href="/structured"
                />
              </div>
              <a href="/considerations" className="first-blag-outer">
                <div className="card">
                  <div className="blog-image">
                    <img
                      src="../../images/gun-black.jpg"
                      alt="blog-1"
                      className="blog-gun-user"
                    />
                  </div>
                  <div className="card-body">
                    <h5 className="card-title">
                      Caliber Considerations for Capacity Restricted ...
                    </h5>
                    <p className="card-text">February 20, 2023</p>
                    <p className="card-sub-title">
                      More capacity is never a bad thing. However, some
                      jurisdictions limit capacity. Why they do this is beyond
                      my comprehension, but it’s a reality we have to deal with
                      and...
                    </p>
                  </div>
                </div>
              </a>
              <div className="col-md-6">
                <BlogCard
                  BlogImage="../../images/car-horan.jpg"
                  BlogHeading="The All New ATF Stabilizing Brace Rule and You"
                  BlogTime="February 9, 2023"
                  BlogTitle="Some folks want more restrictions on firearms. Some gun owners aren’t bothered by restrictions on types of firearms they don’t own. Both groups might want to pay attention to what..."
                  href="/stabilizing"
                />
              </div>
              <div className="col-md-6">
                <BlogCard
                  BlogImage="../../images/mahal.webp"
                  BlogHeading="Government Mandated Firearm Insurance"
                  BlogTime="February 7, 2023"
                  BlogTitle="The city of San Jose, California, has a new city ordinance which requires gun owners who reside within the city limits to carry liability insurance and pay an annual fee...."
                  href="/mandated"
                />
              </div>
              <ul className="page-linking">
                {/* <li>
                                    <Link to="#">
                                        <i className="fa fa-angle-left"></i>
                                    </Link>
                                </li> */}
                <li className="active">
                  <Link to="/righttoblog">1</Link>
                </li>
                <li>
                  <Link to="/government-firearm">2</Link>
                </li>
                <li>
                  <Link to="/government-firearm">
                    <i className="fa fa-angle-right"></i>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </MainLayout>
    </>
  );
};

export default RightToBlog;
