import axios from 'axios';
import { API_BASE_URL } from './endpoint';

const baseURL = API_BASE_URL;

// Function to make a GET request
export const getApi = async (endpoint, access_token, elem) => {
  return new Promise((resolve, reject) => {
    let config = {
      method: 'GET',
      headers: access_token
        ? {
            Authorization: 'Bearer ' + access_token,
            Accept: 'application/json',
          }
        : {},
      body: elem ? JSON.stringify(elem) : elem,
    };
    return axios
      .get(baseURL + endpoint, config)
      .then((response) => {
        return resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// Function to make a POST request
export const postApi = async (endpoint, elem, access_token) => {
  return new Promise((resolve, reject) => {
    let config = {
      method: 'POST',
      headers: access_token
        ? {
            Authorization: 'Bearer ' + access_token,
          }
        : { 'Content-Type': 'application/json' },
    };
    return axios
      .post(baseURL + endpoint, elem, config)
      .then((response) => {
        return resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// Function to make a PATCH request
export const patchApi = async (endpoint, elem, access_token) => {
  return new Promise((resolve, reject) => {
    let config = {
      method: 'PATCH',
      headers: access_token
        ? {
            Authorization: 'Bearer ' + access_token,
          }
        : { 'Content-Type': 'application/json' },
    };
    return axios
      .patch(baseURL + endpoint, elem, config)
      .then((response) => {
        return resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// Function to make a DELETE request
export const deleteApi = async (endpoint, access_token) => {
  return new Promise((resolve, reject) => {
    let config = {
      method: 'DELETE',
      headers: access_token
        ? {
            Authorization: 'Bearer ' + access_token,
          }
        : {},
    };
    return axios
      .delete(baseURL + endpoint, config)
      .then((response) => {
        return resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// Function to make a PUT request
export const putApi = async (endpoint, data, access_token) => {
  const config = {
    method: 'PUT',
    headers: access_token
      ? {
          Authorization: 'Bearer ' + access_token,
        }
      : { 'Content-Type': 'application/json' },
  };

  const response = await axios.put(baseURL + endpoint, data, config);
  return response;
};
